import React, { useEffect, useState } from 'react';
import { Heart, ShoppingBag, Eye, ChevronLeft, ChevronRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { API_BASE_URL, S3_BASE_URL } from '../config/api';
import { addItemToCart } from '../Redux/Customers/Cart/Action';
import { useDispatch } from 'react-redux';

const ShopPage = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [wishlist, setWishlist] = useState(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12;
  const dispatch = useDispatch();

  const addCart = (id) => {
    const jwt = localStorage.getItem("jwt");
    
    if (!jwt) {
      console.error("JWT token not found");
      return;
    }
  
    const reqData = {
      jwt,
      data: {
        productId: id,
        quantity: 1,
      },
    };
  
    dispatch(addItemToCart(reqData));
  };

  const analyzeCategoriesAndLog = (products) => {
    const categoryCount = products.reduce((acc, product) => {
      acc[product.categoryName] = (acc[product.categoryName] || 0) + 1;
      return acc;
    }, {});
  
    console.log("\n=== Category Analysis ===");
    Object.entries(categoryCount)
      .sort(([a], [b]) => a.localeCompare(b))
      .forEach(([category, count]) => {
        const trimmedCategory = category.trim();
        console.log(`"${category}" - ${count} products`);
        console.log(`URL: /shop?category=${encodeURIComponent(trimmedCategory)}\n`);
      });
  
    console.log('Total Categories:', Object.keys(categoryCount).length);
    return categoryCount;
  };

  // Fetch products
  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/api/admin/products/allpublic`);
        const data = await response.json();
        const sortedProducts = data.body.productList.map(product => ({
          ...product,
          imageUrls: product.imageUrls.sort((a, b) => {
            const aIndex = a.indexOf(product.skuNumber);
            const bIndex = b.indexOf(product.skuNumber);
            const aNum = parseInt(a.slice(aIndex + product.skuNumber.length + 1));
            const bNum = parseInt(b.slice(bIndex + product.skuNumber.length + 1));
            return aNum - bNum;
          })
        }));

        // Analyze categories after data is fetched
        analyzeCategoriesAndLog(sortedProducts);
        
        setAllProducts(sortedProducts);
        setFilteredProducts(sortedProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Handle URL parameters for filtering
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const category = params.get('category');
    const minPrice = params.get('minPrice');
    const maxPrice = params.get('maxPrice');
    const color = params.get('color');
    const fabric = params.get('fabric');
    const style = params.get('style');

    let filtered = [...allProducts];

    if (category) {
      filtered = filtered.filter(product => {
        const productCategory = product.categoryName?.trim().toLowerCase();
        const searchCategory = category.trim().toLowerCase();
        
        console.log('Category Comparison:', {
          original: product.categoryName,
          trimmed: productCategory,
          searching: searchCategory,
          matches: productCategory === searchCategory
        });
        
        return productCategory === searchCategory;
      });
    }

    if (minPrice && maxPrice) {
      filtered = filtered.filter(product => 
        product.price >= parseInt(minPrice) && 
        product.price <= parseInt(maxPrice)
      );
    }

    if (color) {
      filtered = filtered.filter(product => 
        product.color?.toLowerCase().trim() === color.toLowerCase().trim()
      );
    }

    if (fabric) {
      filtered = filtered.filter(product => 
        product.fabric?.toLowerCase().trim() === fabric.toLowerCase().trim()
      );
    }

    if (style) {
      filtered = filtered.filter(product => 
        product.style?.toLowerCase().trim() === style.toLowerCase().trim()
      );
    }

    setFilteredProducts(filtered);
    setCurrentPage(1);
  }, [allProducts, window.location.search]);

  // Pagination logic
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const handlePrevPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
  const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));

  // Wishlist handler
  const toggleWishlist = (productId) => {
    setWishlist(prev => {
      const newWishlist = new Set(prev);
      if (newWishlist.has(productId)) {
        newWishlist.delete(productId);
      } else {
        newWishlist.add(productId);
      }
      return newWishlist;
    });
  };

  if (isLoading) {
    return (
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 p-4 md:p-6">
        {[...Array(8)].map((_, index) => (
          <div key={index} className="animate-pulse">
            <div className="bg-gray-200 aspect-[3/4] rounded-lg mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="bg-black text-white py-8 mb-12 text-center">
        <h1 className="text-3xl md:text-4xl font-bold mb-2">Our Collection</h1>
        {/* <p className="text-gray-300 text-sm md:text-base">
          {filteredProducts.length} products available
        </p> */}
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6">
        {currentProducts.map(product => (
          <motion.div
            key={product.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="group relative bg-[#f5eee6] rounded-lg overflow-hidden"
          >
            <div className="aspect-[3/4] relative overflow-hidden">
              <img
                src={S3_BASE_URL + product.imageUrls[0]}
                alt={product.title}
                className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
              />
              
              <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-3 opacity-0 translate-y-4 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-300">
                <button 
                  onClick={(e) => {
                    e.preventDefault();
                    toggleWishlist(product.id);
                  }}
                  className="w-10 h-10 rounded-full bg-white shadow-lg flex items-center justify-center hover:scale-110 transition-transform"
                >
                  <Heart className={`w-4 h-4 ${wishlist.has(product.id) ? 'fill-red-500 text-red-500' : 'text-gray-600'}`} />
                </button>
                <button className="w-10 h-10 rounded-full bg-white shadow-lg flex items-center justify-center hover:scale-110 transition-transform">
                  <Eye className="w-4 h-4 text-gray-600" />
                </button>
                <button 
                  onClick={() => addCart(product.id)} 
                  className="w-10 h-10 rounded-full bg-white shadow-lg flex items-center justify-center hover:scale-110 transition-transform"
                >
                  <ShoppingBag className="w-4 h-4 text-gray-600" />
                </button>
              </div>

              {product.saleTag && (
                <div className="absolute top-3 left-3 bg-purple-600 text-white px-3 py-1 text-xs tracking-wide font-medium rounded">
                  {product.saleTag}
                </div>
              )}
            </div>

            <div className="p-4">
              <h3 className="text-xs font-medium text-gray-800 tracking-wide uppercase mb-2 leading-snug">
                {product.title}
              </h3>
              <p className="text-sm font-semibold text-gray-900">
                ₹{product.price.toLocaleString()}
              </p>
            </div>
          </motion.div>
        ))}
      </div>

      {totalPages > 1 && (
        <div className="flex items-center justify-center gap-4 mt-12">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="p-2 rounded-full border enabled:hover:bg-gray-100 disabled:opacity-50 transition-colors"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          
          <span className="text-sm font-medium">
            Page {currentPage} of {totalPages}
          </span>
          
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="p-2 rounded-full border enabled:hover:bg-gray-100 disabled:opacity-50 transition-colors"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ShopPage;