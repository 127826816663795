import React, { useState } from 'react';
import { Loader2, MapPin, X, CheckCircle, XCircle } from 'lucide-react';

const PincodeChecker = () => {
  const [pincode, setPincode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [pincodeData, setPincodeData] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const checkPincode = async () => {
    if (!pincode || pincode.length !== 6) {
      setError('Please enter a valid 6-digit pincode');
      return;
    }

    setLoading(true);
    setError('');
    setPincodeData(null);

    try {
      const response = await fetch('https://my.ithinklogistics.com/api_v3/pincode/check.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            pincode: pincode,
            access_token: '8ujik47cea32ed386b1f65c85fd9aaaf',
            secret_key: '65tghjmads9dbcd892ad4987jmn602a7'
          }
        })
      });

      const data = await response.json();
      if (data.success) {
        setPincodeData(data.data);
        setIsExpanded(true);
      } else {
        setError(data.message || 'Failed to check pincode');
      }
    } catch (err) {
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed lg:relative top-[-30px] left-0 right-0 z-50 lg:z-30">
      <div className="mx-auto  w-full">
        <div className={`transition-all duration-300 ${isExpanded ? 'translate-y-0' : '-translate-y-full'}`}>
          <div className="  bg-[#111613] lg:bg-slate-400 lg:rounded-lg p-4 shadow-lg">
            <div className="flex lg:hidden  items-center justify-between mb-8">
              <h2 className="text-white font-semibold flex items-center gap-2">
                <MapPin className="w-5 h-5" />
                Check Delivery Availability
              </h2>
              <button 
                onClick={() => setIsExpanded(!isExpanded)}
                className="text-white/80 hover:text-white transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            
            <div className="flex items-center gap-2 bg-white/10 backdrop-blur-lg p-1 rounded-lg">
              <input
                type="text"
                value={pincode}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '');
                  if (value.length <= 6) {
                    setPincode(value);
                    setError('');
                  }
                }}
                placeholder="Enter Pincode"
                className="flex-1 px-4 py-3 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 font-medium placeholder:text-gray-400"
                maxLength={6}
              />
              <button
                onClick={checkPincode}
                disabled={loading}
                className="px-6 py-3 bg-button-green text-white rounded-md font-semibold shadow-sm disabled:opacity-50 
                          transition-colors disabled:cursor-not-allowed"
              >
                {loading ? (
                  <Loader2 className="w-5 h-5 animate-spin" />
                ) : (
                  'Check'
                )}
              </button>
            </div>

            {error && (
              <div className="mt-3 text-white/90 text-sm bg-white/10 p-3 rounded-lg backdrop-blur-lg flex items-center gap-2">
                <XCircle className="w-5 h-5 text-red-300" />
                {error}
              </div>
            )}

            {pincodeData && (
              <div className="mt-4 bg-white rounded-lg p-4 shadow-lg">
                <div className="flex items-center gap-2 mb-3">
                  {pincodeData.delivery_available ? (
                    <>
                      <CheckCircle className="w-5 h-5 text-green-500" />
                      <span className="font-medium text-green-600">Delivery Available</span>
                    </>
                  ) : (
                    <>
                      <XCircle className="w-5 h-5 text-red-500" />
                      <span className="font-medium text-red-600">Delivery Not Available</span>
                    </>
                  )}
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-1">
                    <div className="text-sm text-gray-500">City</div>
                    <div className="font-medium">{pincodeData.city}</div>
                  </div>
                  <div className="space-y-1">
                    <div className="text-sm text-gray-500">State</div>
                    <div className="font-medium">{pincodeData.state}</div>
                  </div>
                  <div className="space-y-1">
                    <div className="text-sm text-gray-500">Country</div>
                    <div className="font-medium">{pincodeData.country}</div>
                  </div>
                  <div className="space-y-1">
                    <div className="text-sm text-gray-500">Pincode</div>
                    <div className="font-medium">{pincodeData.pincode}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        
        {!isExpanded && (
          <button 
            onClick={() => setIsExpanded(true)}
            className="absolute lg:relative lg:hidden top-[80px] -right-20 -translate-x-1/2 bg-[#119c46] text-white px-2 py-1 rounded-full shadow-lg
                     flex items-center gap-2 hover:bg-[#119c46] transition-colors"
          >
            <MapPin className="w-4 h-4" />
          </button>
        )}
      </div>
    </div>
  );
};

export default PincodeChecker;