import React, { useState } from 'react';
import { Heart, ShoppingBag, Eye } from 'lucide-react';
import { useDispatch } from 'react-redux';
import ProductModal from './ProductModal';
import { addItemToCart } from '../../Redux/Customers/Cart/Action';
import { addToWishlist, S3_BASE_URL } from '../../config/api';

const ProductCardmain = ({ product }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const addCart = (e, id) => {
    e.stopPropagation();
    const jwt = localStorage.getItem("jwt");
    
    if (!jwt) {
      console.error("JWT token not found");
      return;
    }
  
    const reqData = {
      jwt,
      data: {
        productId: id,
        quantity: 1
      },
    };
  
    dispatch(addItemToCart(reqData));
  };

  const handleWishlist = (e, id) => {
    e.stopPropagation();
    addToWishlist(id);
  };

  const handleQuickView = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <div 
        className="bg-[#f5eee6] rounded-lg overflow-hidden group relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* Product Card Clickable Link */}
        <div className="relative aspect-[3/4]">
          <a href={`/product/${product.id}`} className="block">
            <img
              src={S3_BASE_URL + product.imageUrls[0]}
              alt={product.title}
              className="w-full line-clamp-1 h-full object-cover cursor-pointer"
            />
          </a>

          {/* Collection Tag */}
          {product.saleTag && (
            <div className="absolute top-3 left-3 bg-purple-600 text-white px-4 py-1 text-xs tracking-wide font-medium rounded">
              {product.saleTag}
            </div>
          )}

          {/* Action Buttons Container */}
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-4 opacity-0 translate-y-4 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-300">
            {/* Add to Cart Button */}
            <button 
              onClick={(e) => addCart(e, product.id)} 
              className="w-10 h-10 rounded-full bg-white shadow-lg flex items-center justify-center hover:scale-110 transition-transform"
            >
              <ShoppingBag className="w-4 h-4 text-gray-600" />
            </button>

            {/* Quick View Button */}
            <button 
              onClick={handleQuickView}
              className="w-10 h-10 rounded-full bg-white shadow-lg flex items-center justify-center hover:scale-110 transition-transform"
            >
              <Eye className="w-4 h-4 text-gray-600" />
            </button>

            {/* Wishlist Button */}
            <button 
              onClick={(e) => handleWishlist(e, product.id)}
              className="w-10 h-10 rounded-full bg-white shadow-lg flex items-center justify-center hover:scale-110 transition-transform"
            >
              <Heart className="w-4 h-4 text-gray-600" />
            </button>
          </div>
        </div>

        {/* Product Details */}
        <div className="p-4">
          <h3 className="text-xs font-medium text-gray-800 tracking-wide uppercase mb-2 leading-snug">
            {product.title}
          </h3>
          <p className="text-sm font-semibold text-gray-900">
            ₹ {product.price.toLocaleString()}
          </p>
        </div>
      </div>

      {/* Product Modal */}
      <ProductModal
        product={product}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default ProductCardmain;