import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Shopbycategories = () => {
  const navigate = useNavigate();

  const categories = [
    { 
      title: 'Arni Silk',
      image: '/api/placeholder/600/400',
      href: '/shop?category=Arni%20silk',
      buttonText: 'Explore →'
    },
    { 
      title: 'Kanchipuram',
      image: '/api/placeholder/600/400',
      href: '/shop?category=Kanchipuram',
      buttonText: 'Explore →'
    },
    { 
      title: 'Kanchipuram Tissue Saree',
      image: '/api/placeholder/600/400',
      href: '/shop?category=Kanchipuram%20Tissue%20Saree',
      buttonText: 'Explore →'
    },
    { 
      title: 'Kerala Cotton',
      image: '/api/placeholder/600/400',
      href: 'shop?category=Kerala%20Cotton',
      buttonText: 'Explore →'
    }
  ];

  const handleNavigate = (href) => {
    navigate(href);
  };

  return (
    <div className=" h-auto bg-white">
      <section className="max-w-6xl mx-auto px-4 py-12">
        <h2 className="text-3xl font-serif text-center mb-2">Shop By category</h2>
        <p className="text-center text-xs px-2 lg:px-20  md:text-base text-gray-600 mb-8">
        Drape Yourself in Heritage: Explore Our Timeless Saree Collection, Meticulously
        Crafted from the Finest Fabrics, Woven with Tradition and Pride
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {categories.map((category, index) => (
            <motion.div
              key={index}
              className="relative rounded-lg overflow-hidden cursor-pointer"
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
              onClick={() => handleNavigate(category.href)}
              role="link"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleNavigate(category.href);
              }}
            >
              <div className="bg-amber-900 aspect-[4/3]">
                <img
                  src={category.image}
                  alt={category.title}
                  className="w-full h-full object-cover opacity-90"
                />
              </div>
              <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/70">
                <h3 className="text-amber-300 text-xl font-medium mb-2">
                  {category.title}
                </h3>
                <button className="text-white text-sm border border-amber-300 px-4 py-1 rounded hover:bg-amber-300 hover:text-black transition-colors">
                  {category.buttonText}
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Shopbycategories;