import React from 'react';
import { ChevronRight } from 'lucide-react';

const Breadcrumbs = ({ items }) => {
  return (
    <nav aria-label="Breadcrumb" className="w-full max-w-screen-xl mx-auto px-4 py-3">
      <ol className="flex flex-wrap items-center gap-2 text-gray-600">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <li className="flex items-center">
              <a
                href={item.url}
                className={`hover:text-gray-900 transition-colors ${
                  index === items.length - 1 
                    ? 'text-gray-900 font-medium' 
                    : 'text-gray-500'
                }`}
              >
                {item.label}
              </a>
            </li>
            {index < items.length - 1 && (
              <li className="flex items-center">
                <ChevronRight className="w-4 h-4 text-gray-400" />
              </li>
            )}
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
};

// Example usage:
// const BreadcrumbExample = () => {
//   const breadcrumbItems = [
//     { label: 'Home', url: '/' },
//     { label: 'Kota Sarees', url: '/kota-sarees' },
//     { label: 'Light Blue Pure Kota Bagru Block Printed Saree', url: '/kota-sarees/light-blue-pure-kota' }
//   ];

//   return <Breadcrumbs items={breadcrumbItems} />;
// };

export default Breadcrumbs;