// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { register } from "../../../Redux/Auth/Action";

// const RegisterUserForm = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [openSnackBar, setOpenSnackBar] = useState(false);
//   const { auth } = useSelector((store) => store);

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const data = new FormData(event.currentTarget);
//     const userData = {
//       firstName: data.get("firstName"),
//       lastName: data.get("lastName"),
//       email: data.get("email"),
//       password: data.get("password"),
//     };
//     dispatch(register(userData));
//   };

//   useEffect(() => {
//     if (auth.user || auth.error) setOpenSnackBar(true);
//   }, [auth]);

//   return (
//     <div className="min-h-screen flex items-center justify-center  relative px-4">
//       <div className="max-w-md w-full space-y-8">
//         {/* Registration Card */}
//         <div className="bg-white rounded-lg shadow-xl overflow-hidden">
//           {/* Registration Header */}
//           <div className="px-8 pt-8 pb-6">
//             <div className="flex justify-center">
//               <div className="h-12 w-12 rounded-full bg-cyan-100 flex items-center justify-center">
//                 <div className="h-6 w-6 rounded-full bg-cyan-400"></div>
//               </div>
//             </div>
//             <h2 className="mt-4 text-2xl font-bold text-center text-gray-900">
//               Create an Account
//             </h2>
//           </div>

//           {/* Registration Form */}
//           <div className="px-8 pb-8">
//             <form onSubmit={handleSubmit} className="space-y-6">
//               <div>
//                 <input
//                   id="firstName"
//                   name="firstName"
//                   type="text"
//                   required
//                   className="w-full px-4 py-3 border-b-2 border-gray-200 focus:border-blue-600 transition-colors bg-transparent outline-none"
//                   placeholder="First Name"
//                 />
//               </div>
//               <div>
//                 <input
//                   id="lastName"
//                   name="lastName"
//                   type="text"
//                   required
//                   className="w-full px-4 py-3 border-b-2 border-gray-200 focus:border-blue-600 transition-colors bg-transparent outline-none"
//                   placeholder="Last Name"
//                 />
//               </div>
//               <div>
//                 <input
//                   id="email"
//                   name="email"
//                   type="email"
//                   required
//                   className="w-full px-4 py-3 border-b-2 border-gray-200 focus:border-blue-600 transition-colors bg-transparent outline-none"
//                   placeholder="Email Address"
//                 />
//               </div>
//               <div>
//                 <input
//                   id="password"
//                   name="password"
//                   type="password"
//                   required
//                   className="w-full px-4 py-3 border-b-2 border-gray-200 focus:border-blue-600 transition-colors bg-transparent outline-none"
//                   placeholder="Password"
//                 />
//               </div>

//               <div>
//                 <button
//                   type="submit"
//                   className="w-full py-3 px-4 text-white font-medium rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
//                   style={{
//                     backgroundImage: "linear-gradient(to left, #006655, #004c40)",
//                   }}
//                 >
//                   Register
//                 </button>
//               </div>
//             </form>

//             <div className="mt-6 text-center">
//               <span className="text-sm text-gray-500">or </span>
//               <button
//                 onClick={() => navigate("/login")}
//                 className="text-sm text-gray-400 hover:text-blue-700 font-medium"
//               >
//                 Login to Your Account
//               </button>
//             </div>
//           </div>
//         </div>

//         {/* Snackbar Notification */}
//         {openSnackBar && (
//           <div
//             className={`mt-4 text-center px-4 py-2 rounded-md ${
//               auth.error ? "bg-red-500 text-white" : "bg-green-500 text-white"
//             }`}
//           >
//             {auth.error ? auth.error : "Registration Successful!"}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default RegisterUserForm;

import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { X, CheckCircle, Phone, Lock, ArrowRight } from 'lucide-react';
import { register } from "../../../Redux/Auth/Action"; // Fixed import path

const IntegratedAuthForm = () => {
  const [authStep, setAuthStep] = useState(1); // 1: Phone, 2: OTP, 3: Registration
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [timer, setTimer] = useState(120);
  const [isResendActive, setIsResendActive] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useDispatch();
  const { auth } = useSelector((store) => store);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  // Properly initialize refs outside of any callback
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const inputRefs = [inputRef1, inputRef2, inputRef3, inputRef4, inputRef5, inputRef6];
  
  useEffect(() => {
    let interval = null;
    if (isResendActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendActive(false);
      setTimer(30);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isResendActive, timer]);

  useEffect(() => {
    if (auth.user || auth.error) setOpenSnackBar(true);
  }, [auth]);

  const handleSendOTP = (e) => {
    e.preventDefault();
    if (phone.length >= 10) {
      setAuthStep(2);
      setIsResendActive(true);
    }
  };

  const handleOtpChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      
      if (value && index < 5) {
        inputRefs[index + 1].current.focus();
      }
      
      // Check if all digits are filled
      if (index === 5 && value && newOtp.every(digit => digit)) {
        setTimeout(() => {
          setIsSuccess(true);
          // Move to registration form after 1 second
          setTimeout(() => setAuthStep(3), 1000);
        }, 500);
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleResend = () => {
    if (!isResendActive) {
      setOtp(['', '', '', '', '', '']);
      setIsResendActive(true);
      setTimer(30);
    }
  };

  const handleRegisterSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userData = {
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      email: data.get("email"),
      password: data.get("password"),
      phone: phone,
    };
    dispatch(register(userData));
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, '');
    const match = phoneNumber.match(/^(\d{1,4})(\d{0,4})(\d{0,4})$/);
    if (match) {
      return match.slice(1).filter(Boolean).join(' ');
    }
    return phoneNumber;
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-white rounded-3xl shadow-xl p-8 relative transition-all duration-300">
        {authStep === 1 && (
          <div className="space-y-6">
            <div className="text-center space-y-2">
              <div className="flex justify-center">
                <div className="w-20 h-20 bg-green-50 rounded-full flex items-center justify-center">
                  <Phone className="w-10 h-10 text-green-500" />
                </div>
              </div>
              <h2 className="text-3xl font-bold text-gray-800 mt-4">
                Verify Your Phone
              </h2>
              <p className="text-gray-600">
                We'll send you a verification code
              </p>
            </div>

            <form onSubmit={handleSendOTP} className="space-y-4">
              <div className="relative">
                <div className="absolute inset-y-0 left-3 flex items-center text-gray-400">
                  <Phone size={20} />
                </div>
                <input
                  type="tel"
                  value={formatPhoneNumber(phone)}
                  onChange={(e) => setPhone(e.target.value.replace(/\D/g, ''))}
                  className="w-full pl-12 pr-4 py-4 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-green-500"
                  placeholder="Enter phone number"
                  maxLength={15}
                />
              </div>
              <button
                type="submit"
                className="w-full py-4 px-6 bg-green-500 text-white font-medium rounded-xl hover:bg-green-600 
                         flex items-center justify-center space-x-2"
                disabled={phone.length < 10}
              >
                <span>Continue</span>
                <ArrowRight size={20} />
              </button>
            </form>
          </div>
        )}

        {authStep === 2 && (
          <div className="space-y-6">
            <div className="text-center space-y-2">
              <div className="flex justify-center">
                <div className="w-20 h-20 bg-green-50 rounded-full flex items-center justify-center">
                  <Lock className="w-10 h-10 text-green-500" />
                </div>
              </div>
              <h2 className="text-3xl font-bold text-gray-800 mt-4">
                Enter Verification Code
              </h2>
              <p className="text-gray-600">
                Enter the 6-digit code we sent to your phone
              </p>
            </div>

            <div className="flex justify-center space-x-3">
              {otp.map((digit, idx) => (
                <input
                  key={idx}
                  ref={inputRefs[idx]}
                  type="text"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleOtpChange(idx, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(idx, e)}
                  className={`w-12 h-14 text-center text-xl font-semibold border-2 rounded-xl 
                           ${isSuccess ? 'border-green-500 bg-green-50' : 'border-gray-200'}`}
                />
              ))}
            </div>

            {isSuccess ? (
              <div className="flex items-center justify-center space-x-2 text-green-500">
                <CheckCircle size={20} />
                <span>Verification Successful!</span>
              </div>
            ) : (
              <div className="text-center space-y-2">
                <div className="text-sm text-gray-500">
                  {isResendActive ? (
                    <span>Resend code in <span className="text-green-500 font-medium">{timer}s</span></span>
                  ) : (
                    <button
                      onClick={handleResend}
                      className="text-green-500 hover:text-green-600 font-medium"
                    >
                      Resend verification code
                    </button>
                  )}
                </div>
                <button 
                  onClick={() => setAuthStep(1)}
                  className="text-sm text-gray-400 hover:text-gray-600"
                >
                  Change phone number
                </button>
              </div>
            )}
          </div>
        )}

        {authStep === 3 && (
          <div className="space-y-6">
            <div className="text-center space-y-2">
              <h2 className="text-3xl font-bold text-gray-800">
                Create Your Account
              </h2>
              <p className="text-gray-600">
                Complete your registration
              </p>
            </div>

            <form onSubmit={handleRegisterSubmit} className="space-y-4">
              <input
                id="firstName"
                name="firstName"
                type="text"
                required
                className="w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:outline-none"
                placeholder="First Name"
              />
              <input
                id="lastName"
                name="lastName"
                type="text"
                required
                className="w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:outline-none"
                placeholder="Last Name"
              />
              <input
                id="email"
                name="email"
                type="email"
                required
                className="w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:outline-none"
                placeholder="Email Address"
              />
              <input
                id="password"
                name="password"
                type="password"
                required
                className="w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:outline-none"
                placeholder="Password"
              />
              <button
                type="submit"
                className="w-full py-4 px-6 bg-green-500 text-white font-medium rounded-xl hover:bg-green-600"
              >
                Complete Registration
              </button>
            </form>
          </div>
        )}

        {openSnackBar && (
          <div
            className={`mt-4 text-center px-4 py-2 rounded-md ${
              auth.error ? "bg-red-500 text-white" : "bg-green-500 text-white"
            }`}
          >
            {auth.error ? auth.error : "Registration Successful!"}
          </div>
        )}
      </div>
    </div>
  );
};

export default IntegratedAuthForm;