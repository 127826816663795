import React from "react";
import HomeCarousel from "../customer/Components/Carousel/HomeCarousel";
import { homeCarouselData } from "../customer/Components/Carousel/HomeCaroselData";
import HomeProductSection from "../customer/Components/Home/HomeProductSection";
import { sareePage1 } from "../Data/Saree/page1";
import { dressPage1 } from "../Data/dress/page1";
import { gounsPage1 } from "../Data/Gouns/gouns";
import { kurtaPage1 } from "../Data/Kurta/kurta";
import { mensShoesPage1 } from "../Data/shoes";
import { mens_kurta } from "../Data/Men/men_kurta";
import { lengha_page1 } from "../Data/Women/LenghaCholi";
import HomePageAddon from "./HomePageAddon";
import HeroSlider from "../customer/Components/slider/HeroSlider";
import HomeCat from "../customer/Components/Home/HomeCat";
import HomeProducts from "../customer/Components/Home/HomeProducts";
import Deals from "../customer/Components/Deals/Deals";
import UpcomingDeals from "../customer/Components/Deals/UpcomingDeals";
import Shopbycategories from "../customer/Components/Home/Shopbycategories";
import Shopbyprice from "../customer/Components/Home/Shopbyprice";
import OurPromises from "../customer/promises/Ourpromises";

const Homepage = () => {

  return (

    <div className="">
     <HeroSlider/>
     <Shopbycategories/>
     <Shopbyprice/>
     <OurPromises/>
     <HomeProducts/>

      
    </div>
  );
};

export default Homepage;
