// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { getUser, login } from '../../../Redux/Auth/Action';


// const LoginUserForm = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const jwt = localStorage.getItem('jwt');
//   const [openSnackBar, setOpenSnackBar] = useState(true);
//   const { auth } = useSelector((store) => store);
  
//   useEffect(() => {
//     if (jwt) {
//       dispatch(getUser(jwt));
//     }
//   }, [jwt]);

//   useEffect(() => {
//     if (auth.user || auth.error) setOpenSnackBar(true);
//   }, [auth.user]);

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const data = new FormData(event.currentTarget);
//     const userData = {
//       email: data.get('email'),
//       password: data.get('password'),
//     };
//     dispatch(login(userData));
//   };

//   return (
//     <div className="min-h-screen flex items-center justify-center  px-4">
//       <div className="max-w-md w-full space-y-8 relative">
//         {/* Background Graphics - Now positioned absolutely */}


//         {/* Login Card */}
//         <div className="bg-white rounded-lg shadow-xl overflow-hidden">
//           {/* Login Header */}
//           <div className="px-8 pt-8 pb-6">
//             <div className="flex justify-center">
//               <div className="h-12 w-12 rounded-full bg-cyan-100 flex items-center justify-center">
//                 <div className="h-6 w-6 rounded-full bg-cyan-400"></div>
//               </div>
//             </div>
//             <h2 className="mt-4 text-2xl font-bold text-center text-gray-900">Login</h2>
//           </div>

//           {/* Login Form */}
//           <div className="px-8 pb-8">
//             <form onSubmit={handleSubmit} className="space-y-6">
//               <div>
//                 <div className="relative">
//                   <input
//                     id="email"
//                     name="email"
//                     type="email"
//                     required
//                     className="w-full px-4 py-3 border-b-2 border-gray-200 focus:border-blue-600 transition-colors bg-transparent outline-none"
//                     placeholder="Email address"
//                   />
//                 </div>
//               </div>

//               <div>
//                 <div className="relative">
//                   <input
//                     id="password"
//                     name="password"
//                     type="password"
//                     required
//                     className="w-full px-4 py-3 border-b-2 border-gray-200 focus:border-blue-600 transition-colors bg-transparent outline-none"
//                     placeholder="Password"
//                   />
//                 </div>
//               </div>

//               <div>
//               <button
//               type="submit"
//               className="w-full py-3 px-4 text-white font-medium rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
//               style={{
//                 backgroundImage: "linear-gradient(to left, #006655, #004c40)",
//               }}
//             >
//               Login
//             </button>

//               </div>
//             </form>

//             <div className="mt-6 text-center">
//               <span className="text-sm text-gray-500">or </span>
//               <button
//                 onClick={() => navigate('/register')}
//                 className="text-sm text-gray-400 hover:text-blue-700 font-medium"
//               >
//                 Create New Acccount
//               </button>
//             </div>
// {/* 
//             <div className="mt-4 text-center">
//               <button
//                 className="text-sm text-gray-500 hover:text-gray-600"
//                 onClick={() => navigate('/forgot-password')}
//               >
//                 Forgot password?
//               </button>
//             </div> */}
//           </div>
//         </div>

//         {/* Snackbar Notification */}

//       </div>
//     </div>
//   );
// };

// export default LoginUserForm;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, login } from '../../../Redux/Auth/Action';

const LoginUserForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jwt = localStorage.getItem('jwt');
  const [openSnackBar, setOpenSnackBar] = useState(true);
  const { auth } = useSelector((store) => store);
  
  useEffect(() => {
    if (jwt) {
      dispatch(getUser(jwt));
    }
  }, [jwt]);

  useEffect(() => {
    if (auth.user || auth.error) setOpenSnackBar(true);
  }, [auth.user]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userData = {
      email: data.get('email'),
      password: data.get('password'),
    };
    dispatch(login(userData));
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-white rounded-3xl shadow-xl p-8 relative transition-all duration-300">
        {/* Logo and Header */}
        <div className="text-center space-y-2">

          <h2 className="text-3xl font-bold text-gray-800 mt-4">
            Welcome Back
          </h2>
          <p className="text-gray-600">
            Sign in to your account
          </p>
        </div>

        {/* Login Form */}
        <form onSubmit={handleSubmit} className="space-y-4 mt-8">
          <div className="relative">
            <input
              id="email"
              name="email"
              type="email"
              required
              className="w-full px-4 py-4 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:outline-none"
              placeholder="Email address"
            />
          </div>

          <div className="relative">
            <input
              id="password"
              name="password"
              type="password"
              required
              className="w-full px-4 py-4 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:outline-none"
              placeholder="Password"
            />
          </div>

          <button
            type="submit"
            className="w-full py-4 px-6 bg-green-500 text-white font-medium rounded-xl hover:bg-green-600 
                     transform transition-all active:scale-95 focus:outline-none focus:ring-2 focus:ring-green-500 
                     focus:ring-offset-2"
          >
            Sign In
          </button>
        </form>

        <div className="mt-6 text-center space-y-2">
          <div className="text-sm">
            <span className="text-gray-500">Don't have an account? </span>
            <button
              onClick={() => navigate('/register')}
              className="text-green-500 hover:text-green-600 font-medium"
            >
              Create One
            </button>
          </div>
        </div>

        {/* Snackbar Notification */}
        {openSnackBar && (
          <div
            className={`mt-4 text-center px-4 py-2 rounded-md ${
              auth.error ? "bg-red-500 text-white" : "bg-green-500 text-white"
            }`}
          >
            {auth.error ? auth.error : "Login Successful!"}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginUserForm;