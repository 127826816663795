// import React, { useEffect, useState, useRef } from 'react';
// import { ChevronRight, ChevronLeft, Eye, Heart, Share2, ShoppingCart ,X } from 'lucide-react';
// import { addToWishlist, API_BASE_URL, S3_BASE_URL } from '../../../config/api';
// import { addItemToCart } from "../../../Redux/Customers/Cart/Action";
// import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';

// import Modal from 'react-modal';
// import { motion, AnimatePresence } from "framer-motion";
// import CertificationDisplay from '../Product/ProductDetails/CertificationDisplay';

// // Custom Button Component
// const Button = ({ children, variant = 'primary', size = 'md', className = '', ...props }) => {
//   const baseStyles = "inline-flex items-center justify-center rounded-lg font-medium transition-all duration-200 active:scale-95";
//   const variants = {
//     primary: "bg-black text-white hover:bg-gray-800",
//     secondary: "bg-white/80 hover:bg-white text-gray-800 shadow-sm",
//     outline: "border border-gray-200 hover:bg-gray-50 text-gray-800"
//   };
//   const sizes = {
//     sm: "px-3 py-1.5 text-sm",
//     md: "px-4 py-2",
//     lg: "px-6 py-3",
//     icon: "p-2"
//   };

//   return (
//     <button 
//       className={`${baseStyles} ${variants[variant]} ${sizes[size]} ${className}`}
//       {...props}
//     >
//       {children}
//     </button>
//   );
// };

// // Custom Badge Component
// const Badge = ({ children, variant = 'primary', className = '' }) => {
//   const variants = {
//     primary: "bg-black text-white",
//     secondary: "bg-gray-100 text-gray-800",
//     destructive: "bg-red-500 text-white",
//     outline: "border border-gray-200 text-gray-800"
//   };

//   return (
//     <span className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ${variants[variant]} ${className}`}>
//       {children}
//     </span>
//   );
// };

// // Custom Card Component
// const Card = ({ children, className = '', ...props }) => {
//   return (
//     <div 
//       className={`bg-white rounded-xl shadow-sm overflow-hidden ${className}`}
//       {...props}
//     >
//       {children}
//     </div>
//   );
// };

// // Custom Skeleton Component
// const Skeleton = ({ className = '' }) => {
//   return (
//     <div className={`animate-pulse bg-gray-200 rounded-lg ${className}`} />
//   );
// };


// function ProductModal({ product, isOpen, onClose }) {
//   const [currentImage, setCurrentImage] = useState(0);
//   const [nextImage, setNextImage] = useState(1);
//   const [direction, setDirection] = useState(1); // 1 for forward, -1 for backward

//   const handlePrev = () => {
//     setDirection(-1);
//     setCurrentImage((prev) =>
//       prev === 0 ? product.imageUrls.length - 1 : prev - 1
//     );
//     setNextImage((prev) =>
//       prev === 0 ? product.imageUrls.length - 1 : prev - 2
//     );
//   };

//   const handleNext = () => {
//     setDirection(1);
//     setCurrentImage((prev) =>
//       prev === product.imageUrls.length - 1 ? 0 : prev + 1
//     );
//     setNextImage((prev) =>
//       prev === product.imageUrls.length - 1 ? 0 : prev + 2
//     );
//   };

//   const imageVariants = {
//     enter: (direction) => ({
//       x: direction > 0 ? 1000 : -1000, // Slide from right for forward, left for backward
//       opacity: 0,
//     }),
//     center: {
//       x: 0, // Center position
//       opacity: 1,
//     },
//     exit: (direction) => ({
//       x: direction > 0 ? -1000 : 1000, // Slide out to the left for forward, right for backward
//       opacity: 0,
//     }),
//   };

//   return (
//     <AnimatePresence>
//       {isOpen && (
//         <div
//           className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 p-4"
//           role="dialog"
//           aria-modal="true"
//         >
//            <button
//               onClick={onClose}
//               className="absolute top-10 right-auto lg:right-4 lg:top-4 z-10 w-[40px] h-[40px] flex items-center justify-center rounded-full bg-white text-gray-600 hover:text-gray-900 transition-colors"
//               aria-label="Close modal"
//             >
//                 <X />
//             </button>
          
//           <motion.div
//             initial={{ opacity: 0, scale: 0.8 }}
//             animate={{ opacity: 1, scale: 1 }}
//             exit={{ opacity: 0, scale: 0.8 }}
//             transition={{ duration: 0.3, ease: "easeInOut" }}
//             className="relative bg-white rounded-xl shadow-xl overflow-hidden w-[100%] lg:w[80%] max-w-4xl max-h-[70vh]"
//           >
//             {/* Close Button */}


//             <div className="flex-1 lg:flex flex-col  md:flex-row h-full">
//               {/* Image Section */}
//               <div className="relative h-[50%]  flex w-[140%] md:w-1/2 bg-gray-100 overflow-hidden">
//                 <div className="relative w-[60vh]  lg:w-full lg:h-full h-[60%]" style={{ aspectRatio: "3 / 4" }}>
//                   {/* Animated Image */}
//                   <AnimatePresence custom={direction}>
//                     <motion.img
//                       key={currentImage}
//                       src={S3_BASE_URL+product.imageUrls[currentImage]}
//                       alt={product.name}
//                       className="absolute inset-0 w-full h-full object-cover"
//                       variants={imageVariants}
//                       initial="enter"
//                       animate="center"
//                       exit="exit"
//                       custom={direction}
//                       transition={{
//                         x: { type: "spring", stiffness: 300, damping: 30 },
//                         opacity: { duration: 0.2 },
//                       }}
//                     />
//                   </AnimatePresence>
//                 </div>
//                 <div className="relative lg:hidden lg:w-full lg:h-full w-[60vh] h-[60%]" style={{ aspectRatio: "3 / 4" }}>
//                   {/* Animated Image */}
//                   <AnimatePresence custom={direction}>
//                     <motion.img
//                       key={currentImage}
//                       src={S3_BASE_URL+product.imageUrls[nextImage]}
//                       alt={product.name}
//                       className="absolute inset-0 w-full h-full object-cover"
//                       variants={imageVariants}
//                       initial="enter"
//                       animate="center"
//                       exit="exit"
//                       custom={direction}
//                       transition={{
//                         x: { type: "spring", stiffness: 300, damping: 30 },
//                         opacity: { duration: 0.2 },
//                       }}
//                     />
//                   </AnimatePresence>
//                 </div>


//                 {/* Navigation Buttons */}
//                 <button
//                   onClick={handlePrev}
//                   className="absolute  lg:left-4 lg:top-1/2 -translate-y-1/2 w-10 h-10 hidden md:flex items-center justify-center rounded-full bg-white shadow-lg text-gray-600 hover:text-gray-900 transition-colors"
//                   aria-label="Previous image"
//                 >
//                   <ChevronLeft className="w-6 h-6" />
//                 </button>
//                 <button
//                   onClick={handleNext}
//                   className="absolute right-4 top-1/2 -translate-y-1/2 w-10 h-10 hidden md:flex items-center justify-center rounded-full bg-white shadow-lg text-gray-600 hover:text-gray-900 transition-colors"
//                   aria-label="Next image"
//                 >
//                   <ChevronRight className="w-6 h-6" />
//                 </button>
//               </div>

//               {/* Product Details Section */}
//               <div className="w-full h-[50%] lg:h-auto md:w-1/2 p-6 flex flex-col">
//               <div className=' flex'>
//               <div className="bg-[#713CBC] block text-white  lg:mb-3 text-[10px] font-medium px-2 py-2 rounded">
//                 {product.saleTag}
//                   </div>
//               </div>

//                 <div className="flex-1">

//                   <span className=" text-[12px] lg:text-sm lg:mb-3 uppercase text-gray-500 font-semibold">
//                     {product.categoryName}
//                   </span>
//                   <h2 className="lg:text-2xl text-lg lg:mb-3 font-bold text-gray-900  mb-4">
//                     {product.title}
//                   </h2>
//                   <div className="mb-1">
//                     <span className="lg:text-2xl text-lg  lg:mb-3 font-bold text-gray-900">
//                       ₹{product.price.toLocaleString()}
//                     </span>
//                     <p className="text-sm ml-3 inline-block text-gray-500 mt-1">
//                       Inclusive of all taxes
//                     </p>
//                   </div>
//                   <div className=' hidden lg:block'>
//                   <CertificationDisplay/>
//                   </div>

//                 </div>



//                 {/* Action Buttons Row */}
//                 <div className="flex items-center justify-between space-x-4 mt-6">
//                   {/* Wishlist Button */}
//                   <div
//                     className="w-full  py-3 px-4 flex items-center justify-center rounded-lg border-2 border-red-500 text-red-500 hover:bg-red-500 hover:text-white transition-colors"
//                     aria-label="Add to wishlist"
//                   >
//                     <Heart className="w-6 h-6 mr-2" />
//                   </div>

//                   {/* Add to Bag Button */}
//                   <div
//                     className="w-full flex justify-center items-center py-3 px-4 bg-gradient-to-r from-pink-500 to-pink-600 hover:from-pink-600 hover:to-pink-700   text-white rounded-lg lg:text-lg font-semibold transition-colors"
//                   >
//                     <ShoppingCart/>
//                   </div>

//                 </div>

//                 {/* More Info Button */}
//                 <div className="mt-4">
//                 <a href={`/product/${product.id}`}>
//                   <button className="w-full py-3 px-4 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg text-lg font-semibold transition-colors flex items-center justify-center">
//                     More Info
//                     <ChevronRight className="w-5 h-5 ml-2" />
//                   </button>
//                  </a>
//                 </div>
//               </div>
//             </div>
//           </motion.div>
//         </div>
//       )}
//     </AnimatePresence>
//   );
// }


// const ProductCard = ({ product }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   const navigate = useNavigate();
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const dispatch = useDispatch();
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const calculateDiscount = () => {
//     if (!product.discountedPrice) return 0;
//     return Math.round(((product.discountedPrice - product.price) / product.discountedPrice) * 100);
//   };

// ;

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);


//   // API call for 'Eye' icon (e.g., View Product)
//   const handleViewProduct = (id) => {
//     navigate(`/product/${id}`)
//   };

//   // API call for 'Heart' icon (e.g., Add to Wishlist)
//   const handleAddToWishlist = (id) => {
//     addToWishlist(id);
//   };

//   // API call for 'Share2' icon (e.g., Share Product)
//   const handleShareProduct = () => {
//     fetch(`${API_BASE_URL}/api/share-product/${product.id}`)
//       .then(response => response.json())
//       .then(data => {
//         console.log("Product shared:", data);
//       })
//       .catch(error => {
//         console.error("Error sharing product:", error);
//       });
//   };

//   // API call for 'ShoppingCart' icon (e.g., Add to Cart)
//   const addCart = (id) => {
//     const reqData = {
//       jwt: localStorage.getItem("jwt"), // Make sure the JWT token is stored and retrieved properly
//       data: {
//         productId: id,
//         quantity: 1, // Default quantity, can be dynamic based on the product
//       },
//     };
  
//     // Dispatch the action
//     dispatch(addItemToCart(reqData));
  
//     // Set popcartnew to true

//   };
  
//   return (
//     <> 
//     <Card
//   className="group relative transition-all duration-300 hover:shadow-xl"
//   onMouseEnter={() => setIsHovered(true)}
//   onMouseLeave={() => setIsHovered(false)}
// > 

//   <div  className="relative w-full pt-[133.33%]"> {/* 3:4 Aspect Ratio */}
//   <a href={`/product/${product.id}`}>
//     {!imageLoaded && (
//       <Skeleton className="absolute inset-0 w-full h-full" />
//     )}
//     <img
//       src={`${S3_BASE_URL}${product.imageUrls[0]}`}
//       alt={product.title}
//       className={`absolute inset-0 w-full h-full object-cover transition-transform duration-500 ${
//         isHovered ? ' brightness-75' : ''
//       }`}
//       onLoad={() => setImageLoaded(true)}
//       style={{ opacity: imageLoaded ? 1 : 0 }}
//     />

//     {product.saleTag && (
//       <Badge variant="destructive" className="absolute top-3 left-3 z-10">
//         {product.saleTag}
//       </Badge>
//     )}
//       </a>

//     <div className="absolute inset-0 transition-colors duration-300 group-hover:bg-black/20" />
//     <div
//       className={`absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2 transition-all duration-300 ${
//         isHovered ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
//       }`}
//     >
//       {['Eye', 'Heart','ShoppingCart'].map((Icon, index) => (
//         <Button
//           key={index}
//           variant="secondary"
//           size="icon"
//           className=" rounded-full"
//           onClick={() => {
//             if (Icon === 'Eye')  openModal();
//             if (Icon === 'Heart') handleAddToWishlist(product.id);
//             if (Icon === 'ShoppingCart') addCart(product.id);
//           }}
//         >
//           {Icon === 'Eye' && <Eye className="h-4 w-4" />}
//           {Icon === 'Heart' && <Heart className="h-4 w-4" />}
//           {Icon === 'ShoppingCart' && <ShoppingCart className="h-4 w-4" />}
//         </Button>
//       ))}
//     </div>
//   </div>


//   <div className="p-4 ">
//     <div className="mb-2">
//       <Badge variant="outline" className="text-xs">
//         {product.categoryName}
//       </Badge>
//     </div>
//     <h3 className="font-semibold text-lg text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap">
//       {product.title}
//     </h3>
//     <div className="mt-2 flex items-center gap-2">
//       <span className="text-xl font-bold text-gray-900">
//         ₹{product.price.toLocaleString()}
//       </span>
//       {product.discountedPrice > 0 && (
//         <span className="text-sm text-gray-500 line-through">
//           ₹{product.discountedPrice.toLocaleString()}
//         </span>
//       )}
//     </div>
//   </div>
// </Card>

    
// <ProductModal
//         product={product}
//         isOpen={isModalOpen}
//         onClose={closeModal}
//       />
    
//     </>


//   );
// };


// const HomeProducts = ({ title = 'Exclusive Saree Collection', subtitle = 'Traditional and contemporary designs', btext = 'View All Products' }) => {
//   const [allProducts, setAllProducts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const sliderRef = useRef(null);
//   const autoScrollRef = useRef(null);

//   useEffect(() => {
//     const fetchProducts = async () => {
//       try {
//         setLoading(true);
//         const response = await fetch(`${API_BASE_URL}/api/admin/products/allpublic`);
        
//         // Check if the response is OK and contains JSON
//         if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        
//         const contentType = response.headers.get("Content-Type");
//         if (!contentType || !contentType.includes("application/json")) {
//           throw new Error("Expected JSON response, but received a non-JSON response");
//         }
        
//         const data = await response.json();
//         const sortedAllProducts = data.body.productList.map((product) => {
//           const sortedImageUrls = product.imageUrls.sort((a, b) => {
//             const aIndex = a.indexOf(product.skuNumber);
//             const bIndex = b.indexOf(product.skuNumber);
  
//             const aNum = parseInt(a.slice(aIndex + product.skuNumber.length + 1));
//             const bNum = parseInt(b.slice(bIndex + product.skuNumber.length + 1));
  
//             return aNum - bNum;
//           });
  
//           return { ...product, imageUrls: sortedImageUrls };
//         });
//         setAllProducts(data.body.productList);
//       } catch (err) {
//         setError(err.message);
//         console.error("Error fetching products:", err); // Log error for debugging
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchProducts();
//   }, []);

//   const scrollSlider = (direction) => {
//     if (sliderRef.current) {
//       const scrollAmount = sliderRef.current.clientWidth * 0.8;
//       sliderRef.current.scrollBy({
//         left: direction === 'left' ? -scrollAmount : scrollAmount,
//         behavior: 'smooth',
//       });
//     }
//   };

//   useEffect(() => {
//     const startAutoScroll = () => {
//       autoScrollRef.current = setInterval(() => {
//         if (sliderRef.current) {
//           const isAtEnd = 
//             sliderRef.current.scrollLeft >= 
//             sliderRef.current.scrollWidth - sliderRef.current.clientWidth;

//           if (isAtEnd) {
//             sliderRef.current.scrollTo({ left: 0, behavior: 'smooth' });
//           } else {
//             scrollSlider('right');
//           }
//         }
//       }, 5000);
//     };

//     startAutoScroll();
//     return () => {
//       if (autoScrollRef.current) clearInterval(autoScrollRef.current);
//     };
//   }, []);

//   if (error) {
//     return (
//       <div className="text-center py-12">
//         <p className="text-red-500">Error loading products: {error}</p>
//         <Button 
//           variant="outline" 
//           className="mt-4"
//           onClick={() => window.location.reload()}
//         >
//           Retry
//         </Button>
//       </div>
//     );
//   }

//   return (
// <section className=" w-full bg-Floral-white my-8 mx-auto rounded-lg py-8">
//   <div className="container mx-auto px-4">
//     {/* Header */}
//     <div className="flex justify-center items-center  border-b pb-6 border-gray-200/30">
//       <div className="max-w-2xl text-center">
//         <h2 className="text-4xl font-playfair font-semibold text-gray-800 leading-tight">
//           {title}
//         </h2>
//         {subtitle && (
//           <p className="mt-2 text-lg text-gray-600">{subtitle}</p>
//         )}
//       </div>
//     </div>

//     {/* Slider Container */}
//     <div className="relative max-w-7xl mx-auto rounded-lg ">
//       {/* Gradient Fades */}
//       <div className="absolute left-0 top-0 h-full w-20 bg-gradient-to-r from-[#f6f2e7] to-transparent z-10"></div>
//       <div className="absolute right-0 top-0 h-full w-20 bg-gradient-to-l from-[#f6f2e7] to-transparent z-10"></div>

//       {/* Slider Navigation */}
//       <Button
//         variant="outline"
//         size="icon"
//         className="absolute -left-6 top-1/2 -translate-y-1/2 z-40 bg-white hover:bg-gray-100 shadow-lg"
//         onClick={() => scrollSlider('left')}
//       >
//         <ChevronLeft className="h-5 w-5 text-gray-600" />
//       </Button>
//       <Button
//         variant="outline"
//         size="icon"
//         className="absolute -right-6 top-1/2 -translate-y-1/2 z-40 bg-white hover:bg-gray-100 shadow-lg"
//         onClick={() => scrollSlider('right')}
//       >
//         <ChevronRight className="h-5 w-5 text-gray-600" />
//       </Button>

//       {/* Slider */}
//       <div
//         ref={sliderRef}
//         className="flex gap-6 overflow-x-auto scroll-smooth px-6 py-8"
//         style={{
//           scrollbarWidth: 'none',
//           msOverflowStyle: 'none',
//           WebkitOverflowScrolling: 'touch',
//         }}
//       >
//         {loading
//           ? Array.from({ length: 4 }).map((_, index) => (
//               <div key={index} className="flex-none w-72">
//                 <Card className="rounded-lg overflow-hidden shadow-md bg-gray-800">
//                   <Skeleton className="aspect-square w-full" />
//                   <div className="p-4">
//                     <Skeleton className="h-4 w-24 mb-2" />
//                     <Skeleton className="h-5 w-40 mb-3" />
//                     <Skeleton className="h-4 w-32" />
//                   </div>
//                 </Card>
//               </div>
//             ))
//           : allProducts.map((product) => (
//               <div
//                 key={product.id}
//                 className="flex-none w-72 pb-12"
//                 style={{ scrollSnapAlign: 'start' }}
//               >
//                 <ProductCard product={product} />
//               </div>
//             ))}
//       </div>
//     </div>

//     {/* Shop Now Button */}
//     <div className="flex justify-center mt-6">
//       <Button
//         variant="solid"
//         onClick={() => (window.location.href = '/shop-page')}
//         className="px-10 py-3 text-lg font-semibold bg-button-green text-white hover:bg-green-700 transition-all rounded-full"
//       >
//         {btext}
//       </Button>
//     </div>
//   </div>
// </section>



//   );
// };

// export default HomeProducts;


import React, { useState, useEffect } from 'react';
import { Heart, Share2 } from 'lucide-react';
import { addToWishlist, API_BASE_URL, S3_BASE_URL } from '../../../config/api';
import { useDispatch } from 'react-redux';
import { Eye, ShoppingBag } from 'lucide-react';
import { addItemToCart } from '../../../Redux/Customers/Cart/Action';


const ProductModal = ({ product, isOpen, onClose }) => {

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const dispatch = useDispatch();


  const addCart = (id) => {
    const jwt = localStorage.getItem("jwt");
    
    if (!jwt) {
      console.error("JWT token not found");
      return;
    }
  
    const reqData = {
      jwt,
      data: {
        productId: id,
        quantity: 1, // Default quantity, can be adjusted dynamically
      },
    };
  
    // Dispatch the action
    dispatch(addItemToCart(reqData));
  };


  useEffect(() => {
    if (isOpen && product) {
      const timer = setInterval(() => {
        setCurrentImageIndex(prev => 
          prev === product.imageUrls.length - 1 ? 0 : prev + 1
        );
      }, 3000);
      return () => clearInterval(timer);
    }
  }, [isOpen, product]);

  if (!isOpen || !product) return null;

  // Debug logging for image URLs
  console.log('Product Images:', product.imageUrls);
  console.log('First Image Full URL:', `${S3_BASE_URL}${product.imageUrls[0]}`);

  const getImageUrl = (imageUrl) => {
    // Remove any leading slashes from the image URL to prevent double slashes
    const cleanImageUrl = imageUrl.startsWith('/') ? imageUrl.slice(1) : imageUrl;
    // Ensure S3_BASE_URL doesn't end with a slash
    const baseUrl = S3_BASE_URL.endsWith('/') ? S3_BASE_URL.slice(0, -1) : S3_BASE_URL;
    return `${baseUrl}/${cleanImageUrl}`;
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 p-4">
      <div className="relative w-full max-w-4xl overflow-hidden rounded-3xl bg-white">
        <div className="flex flex-col md:flex-row">
          {/* Image Section */}
          <div className="relative aspect-square w-full md:w-3/5 overflow-hidden">
            {/* Navigation dots */}
            <div className="absolute right-4 top-1/2 z-10 flex -translate-y-1/2 flex-col gap-2">
              {product.imageUrls.map((_, idx) => (
                <button
                  key={idx}
                  onClick={() => setCurrentImageIndex(idx)}
                  className={`h-2 w-2 rounded-full ${
                    currentImageIndex === idx ? 'bg-white' : 'bg-white/50'
                  } transition-all duration-300`}
                />
              ))}
            </div>
            
            {/* Close button */}
            <button 
              onClick={onClose}
              className="absolute right-4 top-4 z-20 flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-md hover:bg-gray-50 transition-colors"
            >
              <span className="text-lg font-medium text-gray-500">&times;</span>
            </button>

            {/* Image slider */}
            <div 
              className="flex h-full transition-transform duration-700 ease-in-out"
              style={{ transform: `translateX(-${currentImageIndex * 100}%)` }}
            >
              {product.imageUrls.map((image, idx) => (
                <div key={idx} className="relative w-full h-full flex-shrink-0">
                  <img
                    src={getImageUrl(image)}
                    alt={`${product.title} view ${idx + 1}`}
                    className="h-full w-full flex-shrink-0 object-cover"
                    onError={(e) => {
                      console.error('Image failed to load:', getImageUrl(image));
                      e.target.src = '/placeholder-image.jpg'; // Add a placeholder image
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Info Section */}
          <div className="flex flex-col p-6 md:w-2/5">
            <div className="flex-1">
              {product.saleTag && (
                <span className="inline-block bg-button-green text-white text-xs px-2 py-1 rounded">
                  {product.saleTag}
                </span>
              )}
              <h2 className="mt-3 text-xl font-medium text-gray-900">{product.title}</h2>
              
              <div className="mt-4">
                <p className="text-sm text-gray-600">{product.description}</p>
              </div>

              <div className="mt-6">
                <div className="flex items-center gap-2">
                  <span className="text-2xl font-semibold">₹{product.price.toLocaleString()}</span>
                  {product.mrp && product.mrp > product.price && (
                    <span className="text-sm text-gray-500 line-through">
                      ₹{product.mrp.toLocaleString()}
                    </span>
                  )}
                </div>
                {product.mrp && product.mrp > product.price && (
                  <span className="mt-1 inline-block text-sm text-green-600">
                    {Math.round(((product.mrp - product.price) / product.mrp) * 100)}% OFF
                  </span>
                )}
                <p className="mt-2 text-xs text-gray-500">Inclusive of all taxes</p>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="mt-6 space-y-3">
              <button  onClick={() => addCart(product.id)} className="w-full rounded-full mb-2  bg-gradient-to-br from-emerald-900 via-emerald-600 to-emerald-400 py-3.5 text-sm font-medium text-white transition-colors hover:bg-pink-600">
                ADD TO BAG
              </button>
              <a className=' mt-4' href={`/product/${product.id}`}>
              <button className="w-full rounded-full border border-gray-200 py-3.5 text-sm font-medium text-gray-800 transition-colors hover:bg-gray-50">
                VIEW DETAILS →
              </button>
              </a>
            </div>

            {/* Footer Actions */}
            <div className="mt-6 flex justify-start gap-6 border-t border-gray-100 pt-4">
              <button onClick={() => addToWishlist(product.id)} className="flex items-center gap-2 transition-colors hover:text-pink-500">
                <Heart className="h-5 w-5" />
                <span className="text-xs">Wishlist</span>
              </button>
              {/* <button className="flex items-center gap-2 transition-colors hover:text-green-500">
                <Share2 className="h-5 w-5" />
                <span className="text-xs">Share</span>
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductCard = ({ product, onQuickView }) => {
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();
  const addCart = (id) => {
    const jwt = localStorage.getItem("jwt");
    
    if (!jwt) {
      console.error("JWT token not found");
      return;
    }
  
    const reqData = {
      jwt,
      data: {
        productId: id,
        quantity: 1, // Default quantity, can be adjusted dynamically
      },
    };
  
    // Dispatch the action
    dispatch(addItemToCart(reqData));
  };

  return (
    <div className="w-1/2 md:w-1/4 flex-shrink-0 px-2 md:px-3">
      <div 
        className="group relative w-full transition-transform duration-300 hover:-translate-y-1"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="relative mb-3 aspect-[3/4] w-full overflow-hidden rounded-xl">
          <img
            src={S3_BASE_URL + product.imageUrls[0]}
            alt={product.title}
            className="h-full w-full object-cover transition-transform duration-700 group-hover:scale-105"
          />
          {product.saleTag && (
            <div className="absolute top-2 left-2 bg-button-green text-white text-xs px-2 py-1 rounded">
              {product.saleTag}
            </div>
          )}
          
          {/* Icon Buttons Overlay */}
          <div className={`absolute inset-0 flex items-center justify-center gap-4 bg-black/20 transition-opacity duration-300 ${
            isHovered ? 'opacity-100' : 'opacity-0'
          }`}>
            <button 
              onClick={(e) => {
                e.stopPropagation();
                onQuickView(product);
              }}
              className="flex h-10 w-10 items-center justify-center rounded-full bg-white text-gray-800 shadow-lg transition-transform hover:scale-110 hover:bg-gray-50"
            >
              <Eye className="h-5 w-5" />
            </button>
            <button 
             onClick={() => addCart(product.id)}
              className="flex h-10 w-10 items-center justify-center rounded-full bg-white text-gray-800 shadow-lg transition-transform hover:scale-110 hover:bg-gray-50"
            >
              <ShoppingBag className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="flex justify-start items-center flex-col">
          <h3 className="mt-2 text-sm text-gray-900 font-bold line-clamp-1">{product.title}</h3>
          <p className="mt-2 text-sm font-medium text-gray-900">₹{product.price.toLocaleString()}</p>
        </div>
      </div>
    </div>
  );
};

const ProductShowcase = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [products, setproducts] = useState([]);



  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_BASE_URL}/api/admin/products/allpublic`);
        
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        
        const contentType = response.headers.get("Content-Type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("Expected JSON response, but received a non-JSON response");
        }
        
        const data = await response.json();
        const sortedAllProducts = data.body.productList.map((product) => {
          const sortedImageUrls = product.imageUrls.sort((a, b) => {
            const aIndex = a.indexOf(product.skuNumber);
            const bIndex = b.indexOf(product.skuNumber);
  
            const aNum = parseInt(a.slice(aIndex + product.skuNumber.length + 1));
            const bNum = parseInt(b.slice(bIndex + product.skuNumber.length + 1));
  
            return aNum - bNum;
          });
  
          return { ...product, imageUrls: sortedImageUrls };
        });
        setproducts(data.body.productList);
      } catch (err) {
        setError(err.message);
        console.error("Error fetching products:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const maxIndex = isMobile ? 
        Math.ceil(products.length / 2) - 1 : 
        Math.ceil(products.length / 4) - 1;
      
      setCurrentIndex(prev => (prev + 1) > maxIndex ? 0 : prev + 1);
    }, 3000);

    return () => clearInterval(timer);
  }, [products.length, isMobile]);

  const slideWidth = isMobile ? 50 : 25;

  if (loading) return <div className="text-center py-8">Loading...</div>;
  if (error) return <div className="text-center py-8 text-red-500">Error: {error}</div>;

  return (
    <div className=" bg-green-100 px-2 py-16 md:px-4">
      <div className="mx-auto max-w-7xl">
        <div className="mb-12 text-center">
          <h2 className="mb-3 text-3xl font-medium text-gray-900">New Arrivals</h2>
          <p className="text-gray-600">
            Prepare to elevate your sense of style with our latest collection!
          </p>
        </div>

        <div className="relative overflow-hidden">
          <div 
            className="flex transition-transform duration-700 ease-in-out"
            style={{ transform: `translateX(-${currentIndex * slideWidth}%)` }}
          >
            {products.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                onQuickView={setSelectedProduct}
              />
            ))}
          </div>
        </div>

        {selectedProduct && (
          <ProductModal
            product={selectedProduct}
            isOpen={true}
            onClose={() => setSelectedProduct(null)}
          />
        )}

        <div className="mt-12 text-center">
          <a href="/shop-page">
          <button className="rounded-lg bg-button-green px-8 py-3 text-sm font-medium text-white transition-colors ">
            VIEW ALL PRODUCTS
          </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductShowcase;