// import React, { useState, useRef, useEffect } from 'react';
// import { ChevronLeft, ChevronRight, Maximize2 } from 'lucide-react';
// import { S3_BASE_URL } from '../../../config/api';

// const ProductGallery = ({ imageUrls = [] }) => {
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);
//   const [isFullscreen, setIsFullscreen] = useState(false);
//   const [showZoom, setShowZoom] = useState(false);
//   const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
//   const [activeZoomIndex, setActiveZoomIndex] = useState(null);
//   const imageRefs = useRef([]);

//   // Constants for zoom configuration
//   const ZOOM_LEVEL = 2.0;
//   const ZOOM_WIDTH = 400;
//   const ZOOM_HEIGHT = 500;

//   const baseURL =S3_BASE_URL

//   const sortImageUrls = (urls) => {
//     return [...urls].sort((a, b) => {
//       const aNumber = parseInt(a.split('-')[1].split('_')[0]);
//       const bNumber = parseInt(b.split('-')[1].split('_')[0]);
//       return aNumber - bNumber;
//     });
//   };

//   const sortedImageUrls = sortImageUrls(imageUrls);

//   const imageLabels = [
//     { index: 0, label: 'Saree front' },
//     { index: 1, label: 'Pallu' },
//     { index: 2, label: 'Body' },
//     { index: 3, label: 'Blouse' },
//     { index: 4, label: 'Design / Close Up' },
//     { index: 5, label: 'Border' },
//   ];

//   // Initialize refs array when images change
//   useEffect(() => {
//     imageRefs.current = imageRefs.current.slice(0, sortedImageUrls.length);
//   }, [sortedImageUrls]);

//   const handleMouseMove = (e, index) => {
//     if (!imageRefs.current[index]) return;

//     const rect = imageRefs.current[index].getBoundingClientRect();
    
//     // Calculate lens size based on zoom window and zoom level
//     const lensWidth = ZOOM_WIDTH / ZOOM_LEVEL;
//     const lensHeight = ZOOM_HEIGHT / ZOOM_LEVEL;
    
//     // Calculate mouse position relative to image
//     let mouseX = e.clientX - rect.left;
//     let mouseY = e.clientY - rect.top;
    
//     // Calculate lens position (centering lens on mouse)
//     let lensX = mouseX - lensWidth / 2;
//     let lensY = mouseY - lensHeight / 2;
    
//     // Prevent lens from going outside image bounds
//     lensX = Math.max(0, Math.min(lensX, rect.width - lensWidth));
//     lensY = Math.max(0, Math.min(lensY, rect.height - lensHeight));
    
//     // Calculate percentage positions for CSS
//     const xPercent = (lensX / rect.width) * 100;
//     const yPercent = (lensY / rect.height) * 100;

//     setZoomPosition({ 
//       x: xPercent,
//       y: yPercent,
//       lensWidth: (lensWidth / rect.width) * 100,
//       lensHeight: (lensHeight / rect.height) * 100
//     });
//   };

//   const handleMouseEnter = (index) => {
//     setActiveZoomIndex(index);
//     setShowZoom(true);
//   };

//   const handleMouseLeave = () => {
//     setActiveZoomIndex(null);
//     setShowZoom(false);
//   };

//   return (
//     <div className=" relative product-gallery">
//       {/* Desktop view with zoom */}
//       <div className="hidden lg:grid grid-cols-2 gap-2">
//         {sortedImageUrls.map((url, index) => (
//           <div 
//             key={index} 
//             className="relative w-full aspect-w-3 aspect-h-4 overflow-hidden group"
//             onMouseEnter={() => handleMouseEnter(index)}
//             onMouseLeave={handleMouseLeave}
//             onMouseMove={(e) => handleMouseMove(e, index)}
//           >
//             <img
//               ref={el => imageRefs.current[index] = el}
//               src={`${baseURL}${url}`}
//               alt={`Product view ${index + 1}`}
//               className="w-full h-full object-cover"
//             />

//             {/* Zoom lens */}
//             {showZoom && activeZoomIndex === index && (
//               <div 
//                 className="absolute border-2 border-gray-200 pointer-events-none bg-white/10"
//                 style={{
//                   width: `${zoomPosition.lensWidth}%`,
//                   height: `${zoomPosition.lensHeight}%`,
//                   left: `${zoomPosition.x}%`,
//                   top: `${zoomPosition.y}%`
//                 }}
//               />
//             )}

//             {/* Zoomed view */}
//             {showZoom && activeZoomIndex === index && (
//               <div 
//                 className="fixed right-[300px] rounded-xl top-20 bg-white shadow-lg overflow-hidden z-50"
//                 style={{
//                   width: `${ZOOM_WIDTH}px`,
//                   height: `${ZOOM_HEIGHT}px`
//                 }}
//               >
//                 <img
//                   src={`${baseURL}${url}`}
//                   alt={`Zoomed view ${index + 1}`}
//                   className="absolute max-w-none"
//                   style={{
//                     width: `${ZOOM_LEVEL * 100}%`,
//                     height: `${ZOOM_LEVEL * 100}%`,
//                     left: `-${zoomPosition.x * ZOOM_LEVEL}%`,
//                     top: `-${zoomPosition.y * ZOOM_LEVEL}%`
//                   }}
//                 />
//               </div>
//             )}

//             {/* Image label */}
//             <div className="absolute bottom-0 left-0 bg-gradient-to-b from-purple-600 to-purple-900 text-white text-sm py-2 px-4 rounded-br-xl">
//               {imageLabels.find((label) => label.index === index)?.label}
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Mobile view */}
//       <div className="w-full max-w-2xl mx-auto lg:hidden">
//         <div className="relative aspect-[3/4] mb-4 bg-gray-100 rounded-lg overflow-hidden">
//           <img
//             src={`${baseURL}${sortedImageUrls[currentImageIndex]}`}
//             alt={`Product view ${currentImageIndex + 1}`}
//             className="w-full h-full object-cover"
//           />

//           <button
//             onClick={() => setIsFullscreen(true)}
//             className="absolute top-4 right-4 bg-black/50 hover:bg-black/70 p-2 rounded-lg transition-colors"
//             aria-label="View fullscreen"
//           >
//             <Maximize2 className="w-5 h-5 text-white" />
//           </button>
//         </div>

//         <div className="relative px-8">
//           <button
//             onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex - 1 + sortedImageUrls.length) % sortedImageUrls.length)}
//             className="absolute left-0 top-1/2 -translate-y-1/2 bg-white hover:bg-gray-100 p-2 rounded-full shadow-lg z-10"
//             aria-label="Previous image"
//           >
//             <ChevronLeft className="w-4 h-4" />
//           </button>

//           <div className="overflow-x-auto scrollbar-hide">
//             <div className="flex gap-2 justify-start items-center py-2">
//               {sortedImageUrls.map((url, index) => (
//                 <button
//                   key={index}
//                   onClick={() => setCurrentImageIndex(index)}
//                   className={`flex-shrink-0 w-16 h-20 rounded-lg overflow-hidden transition-all ${
//                     currentImageIndex === index
//                       ? 'ring-2 ring-black opacity-100'
//                       : 'ring-1 ring-gray-200 opacity-70 hover:opacity-100'
//                   }`}
//                 >
//                   <img
//                     src={`${baseURL}${url}`}
//                     alt={`Thumbnail ${index + 1}`}
//                     className="w-full h-full object-cover"
//                   />
//                 </button>
//               ))}
//             </div>
//           </div>

//           <button
//             onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex + 1) % sortedImageUrls.length)}
//             className="absolute right-0 top-1/2 -translate-y-1/2 bg-white hover:bg-gray-100 p-2 rounded-full shadow-lg z-10"
//             aria-label="Next image"
//           >
//             <ChevronRight className="w-4 h-4" />
//           </button>
//         </div>
//       </div>

//       {/* Fullscreen Modal */}
//       {isFullscreen && (
//         <div
//           className="fixed inset-0 bg-black z-50 flex items-center justify-center"
//           onClick={() => setIsFullscreen(false)}
//         >
//           <img
//             src={`${baseURL}${sortedImageUrls[currentImageIndex]}`}
//             alt={`Product view ${currentImageIndex + 1}`}
//             className="max-h-screen max-w-screen-lg object-contain"
//           />
//           <button
//             onClick={() => setIsFullscreen(false)}
//             className="absolute top-4 right-4 bg-white/10 text-white w-10 h-10 flex items-center justify-center rounded-full hover:bg-white/20"
//           >
//             ×
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ProductGallery;


import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Maximize2 } from 'lucide-react';
import { S3_BASE_URL } from '../../../config/api';

const ProductGallery = ({ imageUrls = [] }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showZoom, setShowZoom] = useState(false);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [activeZoomIndex, setActiveZoomIndex] = useState(null);
  const imageRefs = useRef([]);

  // Constants for zoom configuration
  const ZOOM_LEVEL = 2.0;
  const ZOOM_WIDTH = 400;
  const ZOOM_HEIGHT = 500;

  const baseURL = S3_BASE_URL;

  const sortImageUrls = (urls) => {
    return [...urls].sort((a, b) => {
      const aNumber = parseInt(a.split('-')[1].split('_')[0]);
      const bNumber = parseInt(b.split('-')[1].split('_')[0]);
      return aNumber - bNumber;
    });
  };

  const sortedImageUrls = sortImageUrls(imageUrls);

  const imageLabels = [
    { index: 0, label: 'Saree front' },
    { index: 1, label: 'Pallu' },
    { index: 2, label: 'Body' },
    { index: 3, label: 'Blouse' },
    { index: 4, label: 'Design / Close Up' },
    { index: 5, label: 'Border' },
  ];

  useEffect(() => {
    imageRefs.current = imageRefs.current.slice(0, sortedImageUrls.length);
  }, [sortedImageUrls]);

  const handleMouseMove = (e, index) => {
    if (!imageRefs.current[index]) return;

    const rect = imageRefs.current[index].getBoundingClientRect();
    
    const lensWidth = ZOOM_WIDTH / ZOOM_LEVEL;
    const lensHeight = ZOOM_HEIGHT / ZOOM_LEVEL;
    
    let mouseX = e.clientX - rect.left;
    let mouseY = e.clientY - rect.top;
    
    let lensX = mouseX - lensWidth / 2;
    let lensY = mouseY - lensHeight / 2;
    
    lensX = Math.max(0, Math.min(lensX, rect.width - lensWidth));
    lensY = Math.max(0, Math.min(lensY, rect.height - lensHeight));
    
    const xPercent = (lensX / rect.width) * 100;
    const yPercent = (lensY / rect.height) * 100;

    setZoomPosition({ 
      x: xPercent,
      y: yPercent,
      lensWidth: (lensWidth / rect.width) * 100,
      lensHeight: (lensHeight / rect.height) * 100
    });
  };

  const handleMouseEnter = (index) => {
    setActiveZoomIndex(index);
    setShowZoom(true);
  };

  const handleMouseLeave = () => {
    setActiveZoomIndex(null);
    setShowZoom(false);
  };

  return (
    <div className="relative product-gallery">
      {/* Desktop view with zoom */}
      <div className="hidden lg:grid grid-cols-2 gap-2">
        {sortedImageUrls.map((url, index) => (
          <div 
            key={index} 
            className="relative w-full aspect-w-3 aspect-h-4 overflow-hidden group"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onMouseMove={(e) => handleMouseMove(e, index)}
          >
            <img
              ref={el => imageRefs.current[index] = el}
              src={`${baseURL}${url}`}
              alt={`Product view ${index + 1}`}
              className="w-full h-full object-cover"
            />

            {showZoom && activeZoomIndex === index && (
              <div 
                className="absolute border-2 border-gray-200 pointer-events-none bg-white/10"
                style={{
                  width: `${zoomPosition.lensWidth}%`,
                  height: `${zoomPosition.lensHeight}%`,
                  left: `${zoomPosition.x}%`,
                  top: `${zoomPosition.y}%`
                }}
              />
            )}

            {showZoom && activeZoomIndex === index && (
              <div 
                className="fixed right-[300px] rounded-xl top-20 bg-white shadow-lg overflow-hidden z-50"
                style={{
                  width: `${ZOOM_WIDTH}px`,
                  height: `${ZOOM_HEIGHT}px`
                }}
              >
                <img
                  src={`${baseURL}${url}`}
                  alt={`Zoomed view ${index + 1}`}
                  className="absolute max-w-none"
                  style={{
                    width: `${ZOOM_LEVEL * 100}%`,
                    height: `${ZOOM_LEVEL * 100}%`,
                    left: `-${zoomPosition.x * ZOOM_LEVEL}%`,
                    top: `-${zoomPosition.y * ZOOM_LEVEL}%`
                  }}
                />
              </div>
            )}

            <div className="absolute bottom-0 left-0 bg-gradient-to-b from-purple-600 to-purple-900 text-white text-sm py-2 px-4 rounded-br-xl">
              {imageLabels.find((label) => label.index === index)?.label}
            </div>
          </div>
        ))}
      </div>

      {/* Mobile view */}
      <div className="w-full max-w-2xl mx-auto lg:hidden">
        <div className="relative h-[80vh] mb-4   rounded-tl-xl  rounded-br-xl  overflow-hidden">
          <img
            src={`${baseURL}${sortedImageUrls[currentImageIndex]}`}
            alt={`Product view ${currentImageIndex + 1}`}
            className="w-full  h-full  bg-cover"
          />

          {/* <button
            onClick={() => setIsFullscreen(true)}
            className="absolute top-4 right-4 bg-black/50 hover:bg-black/70 p-2 rounded-lg transition-colors"
            aria-label="View fullscreen"
          >
            <Maximize2 className="w-5 h-5 text-white" />
          </button> */}
        </div>

        <div className="relative px-8">
          <button
            onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex - 1 + sortedImageUrls.length) % sortedImageUrls.length)}
            className="absolute left-0 top-1/2 -translate-y-1/2 bg-white hover:bg-gray-100 p-2 rounded-full shadow-lg z-10"
            aria-label="Previous image"
          >
            <ChevronLeft className="w-4 h-4" />
          </button>

          <div className="overflow-x-auto scrollbar-hide">
            <div className="flex gap-2 justify-start items-center py-2">
              {sortedImageUrls.map((url, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentImageIndex(index)}
                  className={`flex-shrink-0 w-16 h-20 rounded-lg overflow-hidden transition-all ${
                    currentImageIndex === index
                      ? 'ring-2 ring-black opacity-100'
                      : 'ring-1 ring-gray-200 opacity-70 hover:opacity-100'
                  }`}
                >
                  <img
                    src={`${baseURL}${url}`}
                    alt={`Thumbnail ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </button>
              ))}
            </div>
          </div>

          <button
            onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex + 1) % sortedImageUrls.length)}
            className="absolute right-0 top-1/2 -translate-y-1/2 bg-white hover:bg-gray-100 p-2 rounded-full shadow-lg z-10"
            aria-label="Next image"
          >
            <ChevronRight className="w-4 h-4" />
          </button>
        </div>
      </div>

      {/* Fullscreen Modal */}
      {isFullscreen && (
        <div
          className="fixed inset-0 bg-black z-50 flex items-center justify-center"
          onClick={() => setIsFullscreen(false)}
        >
          <img
            src={`${baseURL}${sortedImageUrls[currentImageIndex]}`}
            alt={`Product view ${currentImageIndex + 1}`}
            className="max-h-screen max-w-screen-lg object-contain"
          />
          <button
            onClick={() => setIsFullscreen(false)}
            className="absolute top-4 right-4 bg-white/10 text-white w-10 h-10 flex items-center justify-center rounded-full hover:bg-white/20"
          >
            ×
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductGallery;