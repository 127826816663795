import React from 'react';

const SareeDisplay = () => {
  return (
    <div className="  max-w-7xl mb-4 mx-auto">
      {/* Image Section */}
      <div className="w-full  h-[200px]  relative overflow-hidden mb-6">
        <div className="absolute  h-full w-full bg-gradient-to-r from-blue-600 to-red-600 opacity-20"></div>
        <img 
          src="https://panjavarnam.com/cdn/shop/products/kanchipuram-silk-saree-handwoven-pure-silk-pure-zari-vaira-oosi-pv-nyc-16-silk-sari-panjavarnam-pv-nyc-16-1.jpg?v=1725122147" 
          alt="Soft Silk Saree Pattern"
          className="w-full h-full object-cover transform "
        />
      </div>

      {/* Details Section */}
      <div className="px-4 md:px-0">
        <h1 className="text-2xl font-medium text-gray-800 mb-4">
          Soft Silk
        </h1>
        <p className="text-gray-600 text-sm text-justify leading-relaxed">
          The Soft Silk saree is lightweight and modern in its appeal. It personifies class and elegance and is highly sought-after. A heirloom from the bygone era, Soft Silk sarees come in myriad colours and contrasting pallus designed with silver/gold zari work. Hand-spun with pure mulberry silk, it is a fusion of rich fabric and comfortable wear, serving as the perfect fit for all events.
        </p>
      </div>
    </div>
  );
};

export default SareeDisplay;