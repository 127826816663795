import React, { useState } from 'react';
import { ChevronRight, ChevronDown } from "lucide-react";

// Custom Card Components
const Card = ({ children, className = "" }) => (
  <div className={`bg-white rounded-lg shadow-sm border border-gray-200 ${className}`}>
    {children}
  </div>
);

const CardContent = ({ children, className = "" }) => (
  <div className={`p-6 ${className}`}>
    {children}
  </div>
);

const FAQPage = () => {
  const [activeTopic, setActiveTopic] = useState('shipping');

  const topics = {
    shipping: {
      title: 'Shipping, Order Tracking & Delivery',
      questions: [
        {
          q: "What does different order status mean?",
          a: "Pending / Pending Payment - Payment was unsuccessful.\nProcessing - Payment Successful, yet to be shipped.\nShipment on Transit - Order has been shipped from our warehouse.\nCancelled - Order has been cancelled due to nonpayment.\nClosed - Successful order cancelled based on customer request and refund has been initiated"
        },
        {
          q: "How do I check the status of my order?",
          a: "We will keep updating you regarding your Order status through WhatsApp from the number which you have provided. You will receive message notifications for:\na) Successful placement of order\nb) Tracking details once the order is dispatched\nc) Order Cancelled"
        },
        {
          q: "Where do we ship?",
          a: "We offer All over India Shipping for our customers. As Per now we ship only in India."
        },
        {
          q: "How is shipping amount decided?",
          a: "Shipping charges on our products shall be mentioned at the time of checkout. These shipping charges are based on the weight of the product, volume after packing, and the destination of delivery."
        }
      ]
    },
    payment: {
      title: 'Payment',
      questions: [
        {
          q: "How can I pay for my order?",
          a: "We accept credit/debit cards, net banking, UPI transaction to make payment online. We have been linked with Razorpay, so you can access all the features that are provided by Razorpay."
        },
        {
          q: "What should I do if my payment fails?",
          a: "Please try again in case of payment failure. This can happen for several reasons. You can also contact your bank and inquire regarding the same because sometimes bank might stop transactions for security reasons."
        },
        {
          q: "What should I do when I have made order and amount also got deducted but order got cancelled?",
          a: "Please write an email to us at support@kalaksha.com with details regarding the transaction and snapshot of the reference number. We will check the issue and will update you on the order status."
        }
      ]
    },
    cancellation: {
      title: 'Cancellation and Modification',
      questions: [
        {
          q: "What is our Cancellation Policy?",
          a: "We accept cancel requests only in case the order is not processed. We will have the order cancelled and process refund. We will not be able to cancel orders if they are already processed."
        },
        {
          q: "How do I cancel my order?",
          a: "You can drop an email at support@kalaksha.com and send a voice note in WhatsApp requesting for the same and stating the reason for cancellation. If the order is not processed yet, we will cancel it for you."
        },
        {
          q: "When will I receive my refund after cancellation?",
          a: "Once an order is canceled, the refund will be initiated and processed within 7-10 working days."
        }
      ]
    }
  };

  const NavItem = ({ active, onClick, children }) => (
    <div 
      className={`p-2 text-gray-700 hover:bg-gray-100 rounded cursor-pointer transition-colors ${
        active ? 'bg-gray-100' : ''
      }`}
      onClick={onClick}
    >
      {children}
    </div>
  );

  const Question = ({ question, answer }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <div className="space-y-2">
        <button 
          onClick={() => setIsExpanded(!isExpanded)}
          className="w-full text-left font-medium text-gray-800 flex items-start gap-2 hover:text-gray-900 transition-colors"
        >
          {isExpanded ? (
            <ChevronDown className="w-5 h-5 mt-0.5 text-gray-400 flex-shrink-0" />
          ) : (
            <ChevronRight className="w-5 h-5 mt-0.5 text-gray-400 flex-shrink-0" />
          )}
          <span>{question}</span>
        </button>
        {isExpanded && (
          <div className="text-gray-600 pl-7 whitespace-pre-line animate-slideDown">
            {answer}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation Breadcrumb */}
      <div className="p-4 bg-white border-b">
        <div className="max-w-7xl mx-auto flex items-center gap-2 text-gray-600">
          <span>Home</span>
          <span>/</span>
          <span className="text-gray-800">Frequently Asked Questions</span>
        </div>
      </div>

      <div className="max-w-7xl mx-auto py-8 px-4 flex gap-8">
        {/* Sidebar Navigation */}
        <div className="w-64 flex-shrink-0">
          <nav className="space-y-1">
            <div className="font-medium p-3 text-gray-800 cursor-pointer hover:text-gray-900">Help / FAQs</div>
            <a 
              href="/terms-and-conditions"
              className="block font-medium p-3 text-gray-800 hover:text-gray-900 hover:bg-gray-50 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>
            <div className="space-y-1 pl-4">
              <NavItem 
                active={activeTopic === 'shipping'}
                onClick={() => setActiveTopic('shipping')}
              >
                Shipping & Payment
              </NavItem>
              <NavItem 
                active={activeTopic === 'payment'}
                onClick={() => setActiveTopic('payment')}
              >
                Returns & Exchange Policy
              </NavItem>
              <NavItem 
                active={activeTopic === 'cancellation'}
                onClick={() => setActiveTopic('cancellation')}
              >
                Cancellation Policy
              </NavItem>
            </div>
            <a 
              href="/cookies-policy"
              className="block font-medium p-3 text-gray-800 hover:text-gray-900 hover:bg-gray-50 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookies Policy
            </a>
            <a 
              href="/disclaimer"
              className="block font-medium p-3 text-gray-800 hover:text-gray-900 hover:bg-gray-50 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              Disclaimer
            </a>
          </nav>
        </div>

        {/* Main Content */}
        <div className="flex-1">
          <Card>
            <CardContent>
              <h1 className="text-2xl font-semibold text-gray-900 mb-8">Frequently Asked Questions</h1>
              
              {/* Display active topic's questions */}
              <div>
                <h2 className="text-xl font-medium text-gray-800 mb-6 pb-2 border-b">
                  {topics[activeTopic].title}
                </h2>
                <div className="space-y-8">
                  {topics[activeTopic].questions.map((item, index) => (
                    <Question 
                      key={index}
                      question={item.q}
                      answer={item.a}
                    />
                  ))}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;