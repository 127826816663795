import React, { useState, useEffect } from 'react';
import { ShoppingCart } from 'lucide-react';
import { Heart } from 'lucide-react';
import { S3_BASE_URL } from '../../../../config/api';
import { addItemToCart } from '../../../../Redux/Customers/Cart/Action';
import { useDispatch } from 'react-redux';
const DesktopBottomCart = ({ title, desc, price, imgeurl , id }) => {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const viewportHeight = window.innerHeight;
      setIsVisible(scrollPosition >= viewportHeight * 0.5);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const addCart = (id) => {
    const jwt = localStorage.getItem("jwt");
    
    if (!jwt) {
      console.error("JWT token not found");
      return;
    }
  
    const reqData = {
      jwt,
      data: {
        productId: id,
        quantity: 1, // Default quantity, can be adjusted dynamically
      },
    };
  
    // Dispatch the action
    dispatch(addItemToCart(reqData));
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  return (
    <div
      className={`fixed inset-x-0 bottom-0 z-50 transition-transform duration-500 ease-in-out ${
        isVisible ? 'translate-y-0' : 'translate-y-full'
      }`}
    >
      <div className="bg-white rounded-t-2xl shadow-[0_-8px_30px_-12px_rgba(0,0,0,0.3)]">
      <div className="absolute top-0 left-0 right-0 h-[2px] bg-gradient-to-r from-yellow-200 via-yellow-400 to-yellow-200" />
        <div className="max-w-6xl mx-auto p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4 flex-1">
              <div className="relative">
                <img
                  src={S3_BASE_URL + imgeurl}
                  alt="Product"
                  className="w-16 h-16 object-cover rounded-xl"
                />
              </div>
              <div className="flex-1">
                <h3 className="text-base font-medium text-gray-900">
                  {truncateText(title || 'Product Title', 4)}
                </h3>
                <div className="mt-1">
                  <span className="text-2xl font-bold text-gray-900">₹{price || '1,27,990'}</span>
                </div>
              </div>
            </div>
            <button onClick={() => addCart(id)} className="px-6 py-3 bg-black text-white font-semibold rounded-full  transition-all duration-300 ease-in-out flex items-center space-x-2">
              <span>Add to Cart</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileBottomCart = ({ title }) => {
  const [isWishlisted, setIsWishlisted] = useState(false);

  const handleWishlist = () => {
    setIsWishlisted(!isWishlisted);
  };

  return (
    <div className="fixed inset-x-0 bottom-0 z-50 bg-white">
      <div className="relative">
        {/* Gold gradient border top */}
        <div className="absolute top-0 left-0 right-0 h-[2px] bg-gradient-to-r from-yellow-200 via-yellow-400 to-yellow-200" />

        <div className="p-4 flex justify-between items-center gap-3">
          {/* Wishlist button */}
          <button
            onClick={handleWishlist}
            className="flex-shrink-0 w-12 h-12 rounded-full border border-gray-200 flex items-center justify-center hover:bg-gray-50"
            aria-label="Add to wishlist"
          >
            <Heart
              className={`w-6 h-6 ${
                isWishlisted ? 'fill-red-500 stroke-red-500' : 'stroke-gray-600'
              }`}
            />
          </button>

          {/* Add to bag button - takes remaining space */}
          <button className="flex-1 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-full py-3 px-6 transition-colors duration-200">
            ADD TO BAG
          </button>
        </div>
      </div>
    </div>
  );
};

const BottomCart = (props) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize, { passive: true });
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile ? <MobileBottomCart {...props} /> : <DesktopBottomCart {...props} />;
};

export default BottomCart;
