import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Navigation from "./customer/Components/Navbar/Navigation";
import CustomerRoutes from "./Routers/CustomerRoutes";
import AdminRoutes from "./Routers/AdminRoutes";
import NotFound from "./Pages/Notfound";
import AdminPannel from "./Admin/AdminPannel";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUser } from "./Redux/Auth/Action";
import FileUpload from "./FileUpload";
import LoginUserForm from "./customer/Components/Auth/Login";
import OTPVerification from "./customer/Components/Auth/Register";
import PriceRangeSlider from "./Pages/shoppage/PriceRangeSlider";
import FAQPage from "./Pages/FAQPage";


// ScrollToTop component to scroll to the top on route change
const ScrollToTop = () => {
  const { pathname } = useLocation(); // Get the current pathname

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, [pathname]); // Run effect on pathname change

  return null; // No UI to render
};

function App() {
  const { auth } = useSelector(store => store);
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("jwt");

  useEffect(() => {
    if (jwt) {
      dispatch(getUser(jwt));
    }
  }, [jwt, dispatch]); // Ensure dispatch is added to dependencies

  return (
    <div className="">
      <ScrollToTop/>
    <Routes>
    <Route path="/test" element={<FileUpload />} />
    <Route path="/sign-in" element={<LoginUserForm />}></Route>
    <Route path="/sign-up" element={<OTPVerification />}></Route>
  
      <Route path="/*" element={<CustomerRoutes />} />
     {auth.user?.role==="ROLE_ADMIN" && <Route path="/admin/*" element={<AdminPannel />} />}
    </Routes>
  </div>
  );
}

export default App;
