import React, { useState, useRef, useEffect } from 'react';
import { ShoppingCart, Heart, Share2, ChevronDown, Truck, ShieldCheck, Package, RefreshCw } from "lucide-react";
import ProductGallery from '../../Product Image Gallery/ProductGallery';
import CertificationDisplay from './CertificationDisplay';
import ChecklistCard from './ChecklistCard';
import BottomCart from './BottomCart';
import Ourpromises from '../../../promises/Ourpromises';
import PincodeChecker from './PincodeChecker';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { findProductById } from "../../../../Redux/Customers/Product/Action";
import { getAllReviews } from "../../../../Redux/Customers/Review/Action";

import { addToWishlist } from '../../../../config/api';
import { addItemToCart } from '../../../../Redux/Customers/Cart/Action';

const ProductDetails = () => {
  const [selectedImage, setSelectedImage] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [showZoomOverlay, setShowZoomOverlay] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  
  const imageRef = useRef(null);
  const zoomOverlayRef = useRef(null);
  
  const { customersProduct } = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productId } = useParams();
  const jwt = localStorage.getItem("jwt");

  useEffect(() => {
    const fetchProductData = async () => {
      setLoading(true);
      setError(null);
      
      try {
        if (!productId) {
          throw new Error('Product ID is required');
        }

        const data = { productId: Number(productId) };
        
        // Use Promise.all to fetch product and reviews concurrently
        await Promise.all([
          dispatch(findProductById(data)),
          dispatch(getAllReviews(productId))
        ]);
        
      } catch (err) {
        console.error('Error fetching product data:', err);
        setError(err.message || 'Failed to load product data');
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [productId, dispatch]);
  
  const addCart = (id) => {
    const jwt = localStorage.getItem("jwt");
    
    if (!jwt) {
      console.error("JWT token not found");
      return;
    }
  
    const reqData = {
      jwt,
      data: {
        productId: id,
        quantity: 1, // Default quantity, can be adjusted dynamically
      },
    };
  
    // Dispatch the action
    dispatch(addItemToCart(reqData));
  };
  const handleMouseMove = (e) => {
    if (!imageRef.current) return;
    const { left, top, width, height } = imageRef.current.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setZoomPosition({ x: Math.min(Math.max(x, 0), 100), y: Math.min(Math.max(y, 0), 100) });
  };

  useEffect(() => {
    if (showZoomOverlay && zoomOverlayRef.current && imageRef.current) {
      const updateOverlayPosition = () => {
        const imageRect = imageRef.current.getBoundingClientRect();
        const overlay = zoomOverlayRef.current;
        overlay.style.left = `${imageRect.right + 20}px`;
        overlay.style.top = `${window.scrollY + imageRect.top}px`;
        overlay.style.height = `${imageRect.height}px`;
        overlay.style.width = `${imageRect.width}px`;
      };

      updateOverlayPosition();
      window.addEventListener('scroll', updateOverlayPosition);
      window.addEventListener('resize', updateOverlayPosition);
      
      return () => {
        window.removeEventListener('scroll', updateOverlayPosition);
        window.removeEventListener('resize', updateOverlayPosition);
      };
    }
  }, [showZoomOverlay]);

  
  const specificationsList = customersProduct?.product ? [
    {
      label: "SAREE DETAILS",
      items: [
        ["Brand", customersProduct.product.brand],
        ["Product Number", customersProduct.product.productNumber],
        ["Dimension", customersProduct.product.dimension],
        ["Origin Place", customersProduct.product.originPlace],
        ["Style", customersProduct.product.style],
        ["Pattern", customersProduct.product.pattern],
        ["Occasion", customersProduct.product.occasion],
        ["Fabric", customersProduct.product.fabric],
        ["Border Size", customersProduct.product.borderSize],
        ["Border Style", customersProduct.product.borderStyle],
        ["Zari", customersProduct.product.zari],
        ["Weight", customersProduct.product.weight],
      ].filter(([key, value]) => value !== null && value !== "") // Filter out null and empty string values
    },
    {
      label: "BLOUSE DETAILS",
      items: [
        ["Blouse Color Family", customersProduct.product.blouseColorFamily],
        ["Blouse Dimension", customersProduct.product.blouseDimension],
        ["Blouse Style", customersProduct.product.blouseStyle],
        ["Blouse Type", customersProduct.product.blouseType],
      ].filter(([key, value]) => value !== null && value !== "") // Filter out null and empty string values
    },
    {
      label: "PALLU DETAILS",
      items: [
        ["Pallu Color", customersProduct.product.palluColor],
        ["Pallu Type", customersProduct.product.palluType],
        ["Body Design", customersProduct.product.bodydesign],
      ].filter(([key, value]) => value !== null && value !== "") // Filter out null and empty string values
    }
  ].filter(section => section.items.length > 0) : [];
  
  const isMobile = () => {
    if (typeof window !== 'undefined') {
      return window.innerWidth < 768;
    }
    return false;
  };

  // Early return for loading state
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-pink-500"></div>
      </div>
    );
  }

  // Early return for error state
  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="text-red-500 mb-4">Error: {error}</div>
        <button 
          onClick={() => window.location.reload()}
          className="px-4 py-2 bg-pink-500 text-white rounded hover:bg-pink-600"
        >
          Try Again
        </button>
      </div>
    );
  }

  // Early return if no product data
  if (!customersProduct?.product) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-gray-500">No product data available</div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-4 md:p-12 md:px-20">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Column - Image Gallery */}
        <div className="space-y-4">
          <ProductGallery imageUrls={customersProduct.product.imageUrls} />
        </div>

        {/* Right Column - Product Details */}
        <div className="space-y-6">
          <div>
            {customersProduct.product.saleTag && (
              <div className="inline-block max-w-xs px-2 py-1 mt-3 bg-gradient-to-r from-purple-500 to-purple-700 border-4 border-purple-600 rounded-lg shadow-xl hover:shadow-lg">
                <h3 className="text-white text-sm font-medium">
                  {customersProduct.product.saleTag}
                </h3>
              </div>
            )}

            <h1 className="text-2xl font-medium mt-6 text-gray-900">
              {customersProduct.product.title}
            </h1>
            <div className=' flex justify-start items-center'>
            <p
              className={`text-base uppercase mr-8 mt-1 ${
                customersProduct.product.quantity === 0 ? "text-red-600" : "text-green-600"
              }`}
            >
              {customersProduct.product.quantity === 0 ? "No Stock" : "In Stock"}
            </p>

            <p className="text-sm uppercase text-gray-500 mt-1">
              SKU: {customersProduct.product.skuNumber}
            </p>
            
            </div>

          </div>

          <div className="flex items-center gap-4">
          <p className={`text-2xl font-medium ${customersProduct.product.quantity === 0 ? 'text-red-500' : 'text-gray-900'}`}>
  {customersProduct.product.quantity === 0
    ? "Product Unavailable"
    : `MRP ₹${customersProduct.product.price?.toLocaleString()}`}
</p>

            <span className="text-sm text-gray-500">(Inclusive of all taxes)</span>
          </div>

          <div className="mt-2">
            <h1 className="pb-2">Description</h1>
            <p className="text-gray-500 text-xs">
              {customersProduct.product.description}
            </p>
          </div>

          {/* Action Buttons */}
          <div className="hidden gap-4  md:flex">
            <button 
          onClick={() => addCart(customersProduct.product.id)}
              className="flex-1  bg-button-green hover:bg-button-green/80 text-white px-6 py-3 rounded-full font-medium transition-all duration-200 flex items-center justify-center gap-2"
            >
              <ShoppingCart className="w-5 h-5 " />
              ADD TO BAG
            </button>
            <button onClick={() => addToWishlist(customersProduct.product.id)} className="p-2 border border-gray-300 rounded-md hover:bg-gray-100">
              <Heart className="w-5 h-5" />
            </button>

          </div>
          

          {/* Specifications */}
          <div className="space-y-4">
  {specificationsList.map((section, idx) => (
    <div key={idx} className="border rounded-lg overflow-hidden">
      <button
        onClick={() => setActiveSection(activeSection === section.label ? '' : section.label)}
        className="w-full px-4 py-3 bg-gray-50 flex justify-between items-center"
      >
        <span className="font-medium text-gray-900">{section.label}</span>
        <ChevronDown 
          className={`w-5 h-5 transition-transform duration-200 ${
            activeSection === section.label ? 'rotate-180' : ''
          }`}
        />
      </button>
      {activeSection === section.label && (
        <div className="divide-y">
          {section.items.map(([key, value], itemIdx) => (
            <div key={itemIdx} className="grid grid-cols-2 p-4">
              <span className="text-gray-600">{key}</span>
              <span className="text-gray-900">{value}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  ))}
</div>
          {/* Features Grid */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 py-6 border-y border-gray-200">
            {[
              { icon: ShieldCheck, text: "100% Authentic" },
              { icon: Truck, text: "Free Shipping" },
              { icon: RefreshCw, text: "Easy Returns" },
              { icon: Package, text: "Secure Packaging" },
            ].map(({ icon: Icon, text }, idx) => (
              <div key={idx} className="flex flex-col items-center text-center gap-2">
                <Icon className="w-6 h-6 text-green-500" />
                <span className="text-sm text-gray-600">{text}</span>
              </div>
            ))}
          </div>

          <CertificationDisplay/>
          <ChecklistCard/>
          <PincodeChecker/>
          <BottomCart title={customersProduct.product.title} id={customersProduct.product.id}  desc={customersProduct.product.description} price={customersProduct.product.price} imgeurl={customersProduct.product.imageUrls[0]}/>
        </div>
      </div>

      {/* Zoom Overlay */}
      {showZoomOverlay && !isMobile() && (
        <div
          ref={zoomOverlayRef}
          className="fixed z-50 bg-white shadow-2xl rounded-lg overflow-hidden"
          style={{
            opacity: isZoomed ? 1 : 0,
            transition: 'opacity 150ms ease-in-out',
            pointerEvents: 'none'
          }}
        >
          <img
            src={customersProduct.product.imageUrls[selectedImage]}
            alt={customersProduct.product.title}
            className="w-full h-full object-cover"
            style={{
              transform: 'scale(2)',
              transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`
            }}
          />
        </div>
      )}

      {/* Related Products Section */}
      <div className="mt-16">
        <h2 className="text-2xl font-bold mb-8">Related Products</h2>
        <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
          {/* Add your related products mapping here */}
        </div>
      </div>

      <Ourpromises/>
    </div>
  );
};

export default ProductDetails;