import React, { useEffect, useState, useRef } from 'react';
import { Heart, ShoppingBag, Eye, ArrowDownUp, X, Filter, LayoutGrid, LayoutList, ListRestart, Plus, Minus, Check, ChevronLeft, ChevronRight, ChevronDown, EyeIcon } from 'lucide-react';
import { addToWishlist, API_BASE_URL, S3_BASE_URL } from '../config/api';
import axios, { all } from "axios";
import { motion } from "framer-motion";
import SareeIntro from '../customer/Components/About saree/SareeIntro';
import SareeList from '../customer/Components/litoutProduct/ListoutProduct';
import PriceRangeSlider from './shoppage/PriceRangeSlider';
import Breadcrumbs from '../customer/Components/Breadcrumb/Breadcrumb';
// Moved constants outside component
import { addItemToCart } from '../Redux/Customers/Cart/Action';
import { useDispatch } from 'react-redux';
import ProductModal from './shoppage/ProductModal';
import ProductCardmain from './shoppage/ProductCardmain';

const breadcrumbItems = [
  { label: 'Home', url: '/' },
  { label: 'Shop Sarees', url: '/shop-page' },
];
const INITIAL_CATEGORIES = [
  { id: 'kanchipuram', label: 'Kanchipuram', color: '#FFF' },
  { id: 'soft-silks', label: 'Soft-silks', color: '#000' },
  { id: 'mysore-silks', label: 'Mysore-silks', color: '#FFF' },
  { id: 'suit-set', label: 'Suit Set', color: '#FFF' }
];
const INITIAL_COLORS = [
  { name: 'red', value: 'red', apiValue: 'RED' },
  { name: 'pink', value: 'pink', apiValue: 'PINK' },
  { name: 'orange', value: 'orange', apiValue: 'ORANGE' },
  { name: 'yellow', value: 'yellow', apiValue: 'YELLOW' },
  { name: 'green', value: 'green', apiValue: 'GREEN' },
  { name: 'blue', value: 'blue', apiValue: 'BLUE' },
  { name: 'purple', value: 'purple', apiValue: 'PURPLE' },
  { name: 'brown', value: 'brown', apiValue: 'BROWN' },
  { name: 'black', value: 'black', apiValue: 'BLACK' },
  { name: 'white', value: 'white', apiValue: 'WHITE' },
  { name: 'gold', value: 'gold', apiValue: 'GOLD' },
  { name: 'silver', value: 'silver', apiValue: 'SILVER' },
  { name: 'cream', value: '#FFFDD0', apiValue: 'CREAM' },
];
const INITIAL_FABRIC = ['Cotton', 'Silk', 'Linen', 'Chiffon'];
const INITIAL_STYLE = ['Traditional', 'Modern', 'Casual', 'Formal'];
const INITIAL_ZARI = ['Gold', 'Silver', 'Copper'];
const INITIAL_PATTERN = ['Floral', 'Geometric', 'Solid', 'Striped'];
var INITIAL_PRODUCTS = [
  {
    id: 1,
    name: "Embroidered Anarkali Suit",
    price: 15999,
    rating: 4.8,
    image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp",
    category: "Mysore-silks"
  },
  {
    id: 2,
    name: "Embroidered Anarkali Suit",
    price: 15999,
    rating: 4.8,
    image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp",
    category: "Kanchipuramsilks"
  },
  {
    id: 3,
    name: "Embroidered Anarkali Suit",
    price: 15999,
    rating: 4.8,
    image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp",
    category: "Kanchipuramsilks"
  },
  {
    id: 4,
    name: "Embroidered Anarkali Suit",
    price: 15999,
    rating: 4.8,
    image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp",
    category: "Suit Set"
  },
  // Add more products as needed...
];

const FestiveEditShop = () => {
  // States
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedFabric, setSelectedFabric] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState([]);
  const [selectedZari, setSelectedZari] = useState([]);
  const [allProducts, setAllProducts] = useState();
  const [selectedPattern, setSelectedPattern] = useState([]);
  const [priceRange, setPriceRange] = useState([10000, 50000]);
  console.log(priceRange)
  const [wishlist, setWishlist] = useState(new Set());
  console.log(allProducts)

  const [sortOption, setSortOption] = useState("Default");
  const [showSortModal, setShowSortModal] = useState(false);
  // New states for load more functionality
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [productsPerLoad] = useState(20);
  const [currentCount, setCurrentCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(true);
  const [isFilterOpen, setIsfilterOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(20);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const addCart = (id) => {
    const jwt = localStorage.getItem("jwt");
    
    if (!jwt) {
      console.error("JWT token not found");
      return;
    }
  
    const reqData = {
      jwt,
      data: {
        productId: id,
        quantity: 1, // Default quantity, can be adjusted dynamically
      },
    };
  
    // Dispatch the action
    dispatch(addItemToCart(reqData));
  };

  // Get and set product all products
  useEffect(() => {
    const getAllProducts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/api/admin/products/allpublic`, {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        });

        const sortedAllProducts = response.data.body.productList.map((product) => {
          const imageUrls = product.imageUrls.sort((a, b) => {
            const aIndex = a.indexOf(product.skuNumber);
            const bIndex = b.indexOf(product.skuNumber);
            const aNum = parseInt(a.slice(aIndex + product.skuNumber.length + 1));
            const bNum = parseInt(b.slice(bIndex + product.skuNumber.length + 1));
            return aNum - bNum;
          });
          return { ...product, imageUrls };
        });

        setAllProducts(sortedAllProducts);
        setFilteredProducts(sortedAllProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getAllProducts();
  }, []);

  // Filter Apply
  useEffect(() => {
    const applyFilters = () => {
      if (!allProducts) return;

      let filtered = [...allProducts];

      // Filter by price range
      filtered = filtered.filter(product =>
        product.price >= priceRange[0] && product.price <= priceRange[1]
      );

      // Filter by categories
      if (selectedCategories.length > 0) {
        filtered = filtered.filter(product =>
          selectedCategories.some(category =>
            product.categoryName?.toLowerCase().includes(category.toLowerCase())
          )
        );
      }

      // Filter by colors
      if (selectedColors.length > 0) {
        filtered = filtered.filter(product =>
          selectedColors.some(color =>
            product.color?.toLowerCase() === color.toLowerCase()
          )
        );
      }

      // Filter by fabric
      if (selectedFabric.length > 0) {
        filtered = filtered.filter(product =>
          selectedFabric.some(fabric =>
            product.fabric?.toLowerCase().includes(fabric.toLowerCase())
          )
        );
      }

      // Filter by style
      if (selectedStyle.length > 0) {
        filtered = filtered.filter(product =>
          selectedStyle.some(style =>
            product.style?.toLowerCase().includes(style.toLowerCase())
          )
        );
      }

      // Filter by zari
      if (selectedZari.length > 0) {
        filtered = filtered.filter(product =>
          selectedZari.some(zari =>
            product.zari?.toLowerCase().includes(zari.toLowerCase())
          )
        );
      }

      // Filter by pattern
      if (selectedPattern.length > 0) {
        filtered = filtered.filter(product =>
          selectedPattern.some(pattern =>
            product.pattern?.toLowerCase().includes(pattern.toLowerCase())
          )
        );
      }
      filtered = filtered.sort((a, b) => {
        switch (sortOption) {
          case "Price: Low to High":
            return a.price - b.price;
          case "Price: High to Low":
            return b.price - a.price;
          default:
            return 0; // Default sorting (original order)
        }
      });

      setFilteredProducts(filtered);
      setCurrentPage(1);
    };

    applyFilters();
  }, [
    allProducts,
    selectedCategories,
    selectedColors,
    selectedFabric,
    selectedStyle,
    selectedZari,
    selectedPattern,
    priceRange,
    sortOption // Add sortOption to dependency array
  ]);

  const handleSortChange = (option) => {
    setSortOption(option);
  };


  // colors filter
  useEffect(() => {
    if (selectedColors.length > 0) {
      console.log('Selected Colors:', selectedColors);
      console.log('Filtered Products:', filteredProducts.map(p => ({
        id: p.id,
        colors: p.colors
      })));
    }
  }, [selectedColors, filteredProducts]);

  const handleColorSelection = (colorName) => {
    setSelectedColors(prev =>
      prev.includes(colorName)
        ? prev.filter(name => name !== colorName)
        : [...prev, colorName]
    );
  };
  const clearAllFilters = () => {
    setSelectedCategories([]);
    setSelectedColors([]);
    setSelectedFabric([]);
    setSelectedStyle([]);
    setSelectedZari([]);
    setSelectedPattern([]);
    setPriceRange([1200, 17000]);
  };

  const clearAllFiltersMD = () => {
    setSelectedCategories([]);
    setSelectedColors([]);
    setSelectedFabric([]);
    setSelectedStyle([]);
    setSelectedZari([]);
    setSelectedPattern([]);
    setPriceRange([1200, 17000]);
    setIsMobileFilterOpen(false)
  };

  useEffect(() => {
    if (filteredProducts.length > 0) {
      const initialProducts = filteredProducts.slice(0, productsPerLoad);
      setDisplayedProducts(initialProducts);
      setCurrentCount(initialProducts.length);
      setHasMore(filteredProducts.length > productsPerLoad);
    }
  }, [filteredProducts]);

  const handleLoadMore = () => {
    const nextProducts = filteredProducts.slice(
      currentCount,
      currentCount + productsPerLoad
    );
    setDisplayedProducts(prev => [...prev, ...nextProducts]);
    setCurrentCount(prev => prev + nextProducts.length);
    setHasMore(currentCount + nextProducts.length < filteredProducts.length);

  };


  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;


  const toggleWishlist = (productId) => {
    setWishlist(prev => {
      const newWishlist = new Set(prev);
      if (newWishlist.has(productId)) {
        newWishlist.delete(productId);
      } else {
        newWishlist.add(productId);
      }
      return newWishlist;
    });
  };

  const [expandedSections, setExpandedSections] = useState({
    price: true,
    category: true,
    size: true,
    color: true
  });


  // Handler functions
  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const handleSelectionChange = (item, setSelected) => {
    setSelected(prev =>
      prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item]
    );
  };

  const handlePriceChange = (e, index) => {
    const newValue = parseInt(e.target.value);
    setPriceRange(prev => {
      const newRange = [...prev];
      newRange[index] = newValue;
      return newRange;
    });
  };

  const getGradientStyle = (index) => {
    const percentage = ((priceRange[index] - 1200) / (17000 - 1200)) * 100;
    return {
      background: `linear-gradient(to right, 
        #4A90E2 0%, 
        #4A90E2 ${percentage}%, 
        #1ABC9C ${percentage}%, 
        #1ABC9C 100%)`
    };
  };

  const FiltersContent = () => (
    <div className="space-y-6">

      <FilterSection
        title="PRICE"
        expanded={expandedSections.price}
        onToggle={() => toggleSection('price')}
      >
        <PriceRangeSlider priceRange={priceRange} setPriceRange={setPriceRange} />
      </FilterSection>

      <FilterSection
        title="CATEGORY"
        expanded={expandedSections.category}
        onToggle={() => toggleSection('category')}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 px-4">
          {INITIAL_CATEGORIES.map(category => (
            <label
              key={category.id}
              className={`px-4 py-2 border cursor-pointer transition-colors ${selectedCategories.includes(category.id)
                ? 'bg-black text-white'
                : 'bg-white text-black hover:bg-gray-50'
                }`}
            >
              <div className="flex items-center justify-between">
                <span className="text-sm">{category.label}</span>
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(category.id)}
                  onChange={() => {
                    setSelectedCategories(prev =>
                      prev.includes(category.id)
                        ? prev.filter(id => id !== category.id)
                        : [...prev, category.id]
                    )
                  }}
                  className="hidden"
                />
              </div>
            </label>
          ))}
        </div>
      </FilterSection>
      <FilterSection title="FABRIC" expanded={expandedSections.fabric} onToggle={() => toggleSection('fabric')}>
        <div className="px-4">
          {INITIAL_FABRIC.map(fabric => (
            <label key={fabric} className="flex items-center space-x-2 cursor-pointer">
              <CustomCheckbox checked={selectedFabric.includes(fabric)} onChange={() => handleSelectionChange(fabric, setSelectedFabric)} />
              <span>{fabric}</span>
            </label>
          ))}
        </div>
      </FilterSection>

      <FilterSection title="STYLE" expanded={expandedSections.style} onToggle={() => toggleSection('style')}>
        <div className="px-4">
          {INITIAL_STYLE.map(style => (
            <label key={style} className="flex items-center space-x-2 cursor-pointer">
              <CustomCheckbox checked={selectedStyle.includes(style)} onChange={() => handleSelectionChange(style, setSelectedStyle)} />
              <span>{style}</span>
            </label>
          ))}
        </div>
      </FilterSection>

      <FilterSection title="ZARI" expanded={expandedSections.zari} onToggle={() => toggleSection('zari')}>
        <div className="px-4">
          {INITIAL_ZARI.map(zari => (
            <label key={zari} className="flex items-center space-x-2 cursor-pointer">
              <CustomCheckbox checked={selectedZari.includes(zari)} onChange={() => handleSelectionChange(zari, setSelectedZari)} />
              <span>{zari}</span>
            </label>
          ))}
        </div>
      </FilterSection>


      <FilterSection
        title="COLOUR"
        expanded={expandedSections.color}
        onToggle={() => toggleSection('color')}
      >
        <div className="grid grid-cols-1 gap-2 px-4">
          {INITIAL_COLORS.map(color => (
            <label
              key={color.name}
              className="flex items-center justify-between py-2 cursor-pointer hover:bg-gray-50 rounded"
            >
              <div className="flex items-center space-x-3">
                <span
                  className="w-5 h-5 rounded-full inline-block border border-gray-200"
                  style={{ backgroundColor: color.value }}
                ></span>
                <span className="text-sm">{color.name}</span>
              </div>
              <CustomCheckbox
                checked={selectedColors.includes(color.name)}
                onChange={() => handleColorSelection(color.name)}
                className="ml-2"
              />
            </label>
          ))}
        </div>
      </FilterSection>
    </div>
  );

  const FiltersContentMD = () => {
    const [expandedSection, setExpandedSection] = useState(null);

    // Toggle the current section
    const toggleSection = (section) => {
      setExpandedSection((prev) => (prev === section ? null : section));
    };

    return (
      <div>
        {/* Price Filter */}
        <FilterSectionMD
          title="PRICE"
          expanded={expandedSection === "price"}
          onToggle={() => toggleSection("price")}
        >
          <div className="relative w-full pt-4">
            {/* Base Track */}
            <div className="absolute w-full h-1 bg-gray-200 rounded-full" />

            {/* Price Range Sliders */}
            {[0, 1].map((index) => (
              <input
                key={index}
                type="range"
                min={1200}
                max={17000}
                value={priceRange[index]}
                onChange={(e) => handlePriceChange(e, index)}
                className="absolute w-full h-1 appearance-none -top-2 rounded-full cursor-pointer transition-all duration-300"
                style={getGradientStyle(index)}
              />
            ))}

            {/* Thumb Styles */}
            <style jsx>{`
              input[type="range"]::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 20px;
                height: 20px;
                background: black;
                border-radius: 50%;
                cursor: pointer;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                transition: transform 0.2s ease;
              }
              input[type="range"]::-webkit-slider-thumb:hover {
                transform: scale(1.1);
              }
            `}</style>
          </div>

          {/* Price Display */}
          <div className="flex justify-between mt-6 text-sm text-gray-700">
            <span>₹{priceRange[0].toLocaleString()}</span>
            <span>₹{priceRange[1].toLocaleString()}</span>
          </div>
        </FilterSectionMD>

        {/* Category Filter */}
        <FilterSectionMD
          title="CATEGORY"
          expanded={expandedSection === "category"}
          onToggle={() => toggleSection("category")}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            {INITIAL_CATEGORIES.map((category) => (
              <label
                key={category.id}
                className={`flex items-center px-3 py-2 border rounded-lg cursor-pointer transition-colors ${selectedCategories.includes(category.id)
                  ? "bg-black text-white"
                  : "bg-white text-black hover:bg-gray-100"
                  }`}
              >
                <span className="text-sm">{category.label}</span>
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(category.id)}
                  onChange={() =>
                    setSelectedCategories((prev) =>
                      prev.includes(category.id)
                        ? prev.filter((id) => id !== category.id)
                        : [...prev, category.id]
                    )
                  }
                  className="hidden"
                />
              </label>
            ))}
          </div>
        </FilterSectionMD>

        {/* Fabric Filter */}
        <FilterSectionMD
          title="FABRIC"
          expanded={expandedSection === "fabric"}
          onToggle={() => toggleSection("fabric")}
        >
          <div className="flex flex-wrap gap-2">
            {INITIAL_FABRIC.map((fabric) => (
              <label key={fabric} className="flex items-center space-x-2 cursor-pointer">
                <CustomCheckbox
                  checked={selectedFabric.includes(fabric)}
                  onChange={() => handleSelectionChange(fabric, setSelectedFabric)}
                />
                <span>{fabric}</span>
              </label>
            ))}
          </div>
        </FilterSectionMD>

        {/* Style Filter */}
        <FilterSectionMD
          title="STYLE"
          expanded={expandedSection === "style"}
          onToggle={() => toggleSection("style")}
        >
          <div className="flex flex-wrap gap-2">
            {INITIAL_STYLE.map((style) => (
              <label key={style} className="flex items-center space-x-2 cursor-pointer">
                <CustomCheckbox
                  checked={selectedStyle.includes(style)}
                  onChange={() => handleSelectionChange(style, setSelectedStyle)}
                />
                <span>{style}</span>
              </label>
            ))}
          </div>
        </FilterSectionMD>

        {/* Color Filter */}
        <FilterSectionMD
          title="COLOUR"
          expanded={expandedSection === "color"}
          onToggle={() => toggleSection("color")}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            {INITIAL_COLORS.map((color) => (
              <label
                key={color.name}
                className="flex items-center justify-between py-2 cursor-pointer hover:bg-gray-50 rounded"
              >
                <div className="flex items-center space-x-3">
                  <span
                    className="w-5 h-5 rounded-full border"
                    style={{ backgroundColor: color.value }}
                  ></span>
                  <span className="text-sm">{color.name}</span>
                </div>
                <CustomCheckbox
                  checked={selectedColors.includes(color.name)}
                  onChange={() => handleColorSelection(color.name)}
                  className="ml-2"
                />
              </label>
            ))}
          </div>
        </FilterSectionMD>
      </div>
    );
  };

  return (
    <div className=' w-full  h-auto flex justify-center items-center'>
      <div className="min-h-screen max-w-7xl bg-white">
        <div className="  h-auto py-6 text-white bg-black w-full flex justify-center items-center">
          <div className=" flex justify-center flex-col items-center ">
            <h1 className="text-xl md:text-5xl lg:text-5xl font-bold ">Summer Collection</h1>
            <p className="text-[11px] md:text-xl lg:text-xl  opacity-90">Discover our latest arrivals</p>
          </div>

        </div>




        <div className="  w-full lg:w-[80%] z-50 mx-auto px-4 py-8">

          {isMobileFilterOpen && (
            <div className='   w-full z-50 fixed min-h-[60vh] max-h-[60vh]  lg:hidden '>
              <FilterSort sortOption={sortOption} setSortOption={setSortOption} priceRange={priceRange} setPriceRange={setPriceRange} selectedCategories={selectedCategories}
                selectedColors={selectedColors}
                selectedFabric={selectedFabric}
                selectedStyle={selectedStyle}
                selectedZari={selectedZari}
                selectedPattern={selectedPattern}
                onCategoryChange={setSelectedCategories}
                onColorChange={setSelectedColors}
                onFabricChange={setSelectedFabric}
                onStyleChange={setSelectedStyle}
                onZariChange={setSelectedZari}
                onPatternChange={setSelectedPattern} />
            </div>
          )}


          {showSortModal && (
            <SortBy onSortChange={handleSortChange} />
            // <SortBy
            //   onClose={() => setShowSortModal(false)}
            //   onSortChange={(option) => {
            //     console.log('Selected sort option:', option);
            //     setShowSortModal(false);
            //   }}
            // />
          )}



          {!isFilterOpen && (
            <div className="border border-gray-200 rounded-lg shadow-sm mb-8 hidden lg:block">
              <div className="flex h-auto justify-between items-start">

                {/* Price Range Filter */}
                <div className="w-[20%] ">
                  <PriceRangeSlider priceRange={priceRange} setPriceRange={setPriceRange} />
                </div>

                {/* Divider */}
                <div className="border-l border-gray-200 h-full mx-4" />

                {/* Main Filters */}
                <div className=" flex w-[80%] h-full  justify-center items-center">
                  <TopFilter
                    selectedCategories={selectedCategories}
                    selectedColors={selectedColors}
                    selectedFabric={selectedFabric}
                    selectedStyle={selectedStyle}
                    selectedZari={selectedZari}
                    selectedPattern={selectedPattern}
                    onCategoryChange={setSelectedCategories}
                    onColorChange={setSelectedColors}
                    onFabricChange={setSelectedFabric}
                    onStyleChange={setSelectedStyle}
                    onZariChange={setSelectedZari}
                    onPatternChange={setSelectedPattern}
                  />
                </div>

              </div>
            </div>
          )}


          <Breadcrumbs items={breadcrumbItems} />
          <div className="  max-w-7xl items-center justify-between mb-8 px-2  hidden lg:flex ">
            <div className='flex'>
              <h2 className="text-2xl md:text-3xl font-bold text-black mb-2">Filter By</h2>
              <button
                onClick={clearAllFilters}
                className="flex items-center justify-center space-x-2 px-4 py-2 border-none bg-transparent text-black hover:text-gray-300 transition-all duration-800 ease-in-out transform hover:scale-105"
              >
                {/* 3-bar vertical icon */}
                <ListRestart />
              </button>

            </div>

            <div className="flex bg-gray-100 p-1 px-2 justify-between items-center w-[30%] space-x-4">
              <button
                onClick={() => setIsfilterOpen(true)}
                className="flex items-center justify-center p-2 rounded-lg hover:bg-white transition-all duration-300 group"
              >
                <LayoutGrid className="w-5 h-5 text-gray-600 group-hover:text-blue-600" />
              </button>
              <button
                onClick={() => setIsfilterOpen(false)}
                className="flex items-center justify-center p-2 rounded-lg hover:bg-white transition-all duration-300 group"
              >
                <LayoutList className="w-5 h-5 text-gray-600 group-hover:text-blue-600" />
              </button>


            </div>

            <SortBy onSortChange={handleSortChange} />
          </div>





          <div className="flex gap-0 lg:gap-8">
            {/* Desktop Filters Sidebar */}
            {/* <div className="hidden lg:block w-72 flex-shrink-0 border-[1px]">
            <FiltersContent />
          </div> */}

            {/* Product Grid */}


            <div className=" mb-4">

            </div>


            {isFilterOpen && (

              <div className="hidden lg:block w-72 flex-shrink-0 border-[1px]">

                <FiltersContent />
              </div>


            )}


            {/* Productsdisplay */}

            <div className="max-w-7xl mx-auto lg:px-4 lg:py-8">
              < SareeIntro />

              <div
                className={`grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2 ${isFilterOpen ? "lg:grid-cols-3" : "lg:grid-cols-4"
                  }`}
              >
 <>
      {/* Your products grid/list */}
      {displayedProducts.map(product => (
    <ProductCardmain key={product.id} product={product} />
  ))}

      {/* Modal */}
      <ProductModal
        product={selectedProduct}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedProduct(null);
        }}
      />
    </>
              </div>

              {hasMore && (
                <div className="flex justify-center mt-8">
                  <button
                    onClick={handleLoadMore}
                    className="px-6 py-3 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors duration-200 flex items-center gap-2"
                  >
                    Load More
                    <Plus className="w-4 h-4" />
                  </button>
                </div>
              )}

              {/* No products found message */}
              {displayedProducts.length === 0 && (
                <div className="text-center py-12">
                  <h3 className="text-lg font-medium text-gray-900">No products found</h3>
                  <p className="mt-2 text-gray-500">Try adjusting your filters or search criteria</p>
                </div>
              )}
            </div>




          </div>
        </div>
        <SareeList products={displayedProducts} namelist="Sarees" />

      </div>
    </div>
  );
};

export default FestiveEditShop;


// =====================================================================================================
// ======================================================================================================

const SortByModal = ({ onClose, onSortChange }) => {
  const sortOptions = [
    { id: 'new-arrivals', label: 'New Arrivals' },
    { id: 'price-low-high', label: 'Price Low To High' },
    { id: 'price-high-low', label: 'Price High To Low' },
    { id: 'most-popular', label: 'Most Popular' },
    { id: 'top-sellers', label: 'Top Sellers' },
    { id: 'recommended', label: 'Recommended' },
    { id: 'discounts', label: 'Discounts' }
  ];

  const [selectedOption, setSelectedOption] = useState('new-arrivals');

  const handleOptionClick = (optionId) => {
    setSelectedOption(optionId);
    onSortChange(optionId);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center pt-16">
      <div className="bg-white w-full max-w-sm rounded-t-lg">
        <div className="p-4 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-medium">SORT BY</h2>
            <button
              onClick={onClose}
              className="p-1 hover:bg-gray-100 rounded-full"
            >
              <X className="w-5 h-5 text-gray-500" />
            </button>
          </div>
        </div>

        <div className="py-2">
          {sortOptions.map((option) => (
            <button
              key={option.id}
              className={`w-full px-4 py-3 text-left hover:bg-gray-50 
                ${selectedOption === option.id ? 'text-pink-500' : 'text-gray-700'}`}
              onClick={() => handleOptionClick(option.id)}
            >
              <div className="flex items-center justify-between">
                <span>{option.label}</span>
                {selectedOption === option.id && (
                  <div className="w-5 h-5 rounded-full bg-pink-500 flex items-center justify-center">
                    <svg
                      className="w-3 h-3 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const CustomCheckbox = ({ checked, onChange, className = "" }) => (
  <div
    className={`w-5 h-5 border border-gray-300 rounded flex items-center justify-center transition-colors ${checked ? 'bg-black border-black' : 'bg-white'
      } ${className}`}
    onClick={onChange}
  >
    {checked && <Check className="w-3 h-3 text-white" />}
  </div>
);



const SortBy = ({ onSortChange }) => {
  const [selectedOption, setSelectedOption] = useState("Default");

  const options = ["Default", "Price: Low to High", "Price: High to Low"];

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    onSortChange(value);
  };

  return (
    <div className="flex items-center space-x-2">
      <label htmlFor="sort-by" className="text-gray-700 inline font-medium">
        <svg className="-ms-0.5 me-2 h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 4v16M7 4l3 3M7 4 4 7m9-3h6l-6 6h6m-6.5 10 3.5-7 3.5 7M14 18h4" />
        </svg>
        Sort
      </label>
      <select
        id="sort-by"
        value={selectedOption}
        onChange={handleChange}
        className="px-4 py-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};


const FilterSection = ({ title, expanded, onToggle, children }) => (
  <div className="border-b border-gray-200 py-4">
    <div className="flex justify-between items-center mb-4 cursor-pointer" onClick={onToggle}>
      <h3 className="font-medium text-gray-900">{title}</h3>
      {expanded ? (
        <Minus className="w-4 h-4" />
      ) : (
        <Plus className="w-4 h-4" />
      )}
    </div>
    {expanded && children}
  </div>
);


const FilterSectionMD = ({ title, expanded, onToggle, children }) => {
  return (
    <div className="border-b border-gray-300 py-4">
      {/* Header Section */}
      <div
        className="flex justify-between items-center mb-2 cursor-pointer hover:bg-gray-100 p-2 rounded-lg transition-all"
        onClick={onToggle}
      >
        <h3 className="font-semibold text-lg text-gray-800">{title}</h3>
        <div
          className={`w-5 h-5 flex items-center justify-center rounded-full bg-gray-200 text-gray-600 transition-transform ${expanded ? "rotate-180" : "rotate-0"
            }`}
        >
          {expanded ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          )}
        </div>
      </div>
      {/* Expandable Content */}
      <motion.div
        initial={false}
        animate={{ height: expanded ? "auto" : 0 }}
        className="overflow-hidden transition-all"
      >
        <div className="p-2">{children}</div>
      </motion.div>
    </div>
  );
};



const FilterOption = React.memo(({ option, isSelected, onClick, type }) => {
  const [isHovered, setIsHovered] = useState(false);

  if (type === 'color') {
    return (
      <button
        onClick={() => onClick(option)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`flex items-center space-x-3 w-full px-3 py-2.5 rounded-md transition-all duration-200
          ${isSelected ? 'bg-gray-100' : isHovered ? 'bg-gray-50' : ''}`}
      >
        <span
          className={`w-5 h-5 rounded-full border-2 transition-transform duration-200
            ${isSelected ? 'border-black scale-110' : 'border-gray-200'}`}
          style={{ backgroundColor: option.value }}
        />
        <span className="text-sm font-medium">{option.name}</span>
        {isSelected && (
          <Check className="w-4 h-4 ml-auto text-black" />
        )}
      </button>
    );
  }

  return (
    <button
      onClick={() => onClick(option)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`flex items-center justify-between w-full px-4 py-2.5 text-left transition-all duration-200
        ${isSelected ? 'bg-gray-100' : isHovered ? 'bg-gray-50' : ''}`}
    >
      <span className="text-sm font-medium">
        {typeof option === 'object' ? option.label : option}
      </span>
      {isSelected && (
        <Check className="w-4 h-4 text-black" />
      )}
    </button>
  );
});

const TopFilter = ({
  onCategoryChange,
  onColorChange,
  onFabricChange,
  onStyleChange,
  onZariChange,
  onPatternChange,
  selectedCategories,
  selectedColors,
  selectedFabric,
  selectedStyle,
  selectedZari,
  selectedPattern
}) => {
  const [activeFilter, setActiveFilter] = useState(null);
  const filterRef = useRef(null);
  const [filterPositions, setFilterPositions] = useState({});

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setActiveFilter(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filters = [
    {
      id: 'categories',
      label: 'CATEGORIES',
      options: INITIAL_CATEGORIES,
      selected: selectedCategories,
      onChange: onCategoryChange,
      type: 'category'
    },
    {
      id: 'colors',
      label: 'COLORS',
      options: INITIAL_COLORS,
      selected: selectedColors,
      onChange: onColorChange,
      type: 'color'
    },
    {
      id: 'fabric',
      label: 'FABRIC',
      options: INITIAL_FABRIC,
      selected: selectedFabric,
      onChange: onFabricChange
    },
    {
      id: 'style',
      label: 'STYLE',
      options: INITIAL_STYLE,
      selected: selectedStyle,
      onChange: onStyleChange
    },
    {
      id: 'zari',
      label: 'ZARI TYPE',
      options: INITIAL_ZARI,
      selected: selectedZari,
      onChange: onZariChange
    },
    {
      id: 'pattern',
      label: 'PATTERN',
      options: INITIAL_PATTERN,
      selected: selectedPattern,
      onChange: onPatternChange
    }
  ];

  const handleSelect = (filter, value) => {
    let newSelection;
    if (filter.type === 'category') {
      const category = typeof value === 'object' ? value.id : value;
      newSelection = filter.selected.includes(category)
        ? filter.selected.filter(id => id !== category)
        : [...filter.selected, category];
    } else if (filter.type === 'color') {
      const colorName = value.name;
      newSelection = filter.selected.includes(colorName)
        ? filter.selected.filter(name => name !== colorName)
        : [...filter.selected, colorName];
    } else {
      newSelection = filter.selected.includes(value)
        ? filter.selected.filter(item => item !== value)
        : [...filter.selected, value];
    }

    filter.onChange(newSelection);
    setActiveFilter(null);
  };

  const getOptionLabel = (filter, value) => {
    if (filter.type === 'category') {
      return filter.options.find(c => c.id === value)?.label;
    }
    if (filter.type === 'color') {
      return filter.options.find(c => c.name === value)?.name;
    }
    return value;
  };

  return (
    <div className="relative" ref={filterRef}>
      <div className="flex items-center border-b border-gray-200">
        {filters.map((filter) => (
          <div key={filter.id} className="relative">
            <button
              onClick={() => setActiveFilter(activeFilter === filter.id ? null : filter.id)}
              className="group px-4 py-2.5 flex items-center justify-between gap-2 
                       hover:bg-gray-50 transition-colors
                       text-gray-700 border-r border-gray-200 last:border-r-0"
            >
              <span className="text-sm font-medium group-hover:text-black transition-colors">
                {filter.label}
                {filter.selected?.length > 0 && (
                  <span className="ml-1.5 px-2 py-0.5 text-xs font-semibold bg-black text-white rounded-full">
                    {filter.selected.length}
                  </span>
                )}
              </span>
              <ChevronDown
                className={`w-4 h-4 text-gray-500 transition-transform duration-200
                  ${activeFilter === filter.id ? 'rotate-180' : ''}`}
              />
            </button>

            {activeFilter === filter.id && (
              <div
                className="absolute z-50 w-72 mt-1 bg-white border border-gray-200 rounded-lg shadow-lg
                          transform opacity-0 scale-95 animate-in"
                style={{
                  animation: 'slideIn 0.2s ease-out forwards',
                }}
              >
                <div className="max-h-72 overflow-y-auto p-2">
                  <div className="grid grid-cols-1 gap-1">
                    {filter.options.map((option) => (
                      <FilterOption
                        key={filter.type === 'color' ? option.name : (option.id || option)}
                        option={option}
                        isSelected={filter.selected.includes(
                          filter.type === 'color' ? option.name : (option.id || option)
                        )}
                        onClick={(value) => handleSelect(filter, value)}
                        type={filter.type}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {filters.some(filter => filter.selected?.length > 0) && (
        <div className="flex flex-wrap gap-2 p-4 border-b border-gray-200 animate-in"
          style={{ animation: 'fadeIn 0.3s ease-out' }}>
          {filters.map(filter =>
            filter.selected?.map(value => (
              <span
                key={`${filter.id}-${value}`}
                className="inline-flex items-center gap-1.5 px-4 py-1.5 bg-gray-100 
                         rounded-full text-sm group hover:bg-gray-200 transition-colors"
              >
                {filter.type === 'color' && (
                  <span
                    className="w-3 h-3 rounded-full border border-gray-300"
                    style={{
                      backgroundColor:
                        filter.options.find(c => c.name === value)?.value
                    }}
                  />
                )}
                {getOptionLabel(filter, value)}
                <button
                  onClick={() => handleSelect(filter,
                    filter.options.find(o =>
                      (filter.type === 'color' && o.name === value) ||
                      (filter.type === 'category' && o.id === value) ||
                      o === value
                    )
                  )}
                  className="p-0.5 hover:bg-gray-300 rounded-full transition-colors"
                >
                  <X className="w-3.5 h-3.5" />
                </button>
              </span>
            ))
          )}
        </div>
      )}

      <style jsx>{`
        @keyframes slideIn {
          from {
            opacity: 0;
            transform: translateY(-4px) scale(0.95);
          }
          to {
            opacity: 1;
            transform: translateY(0) scale(1);
          }
        }
        
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

const PriceRangeFilter = ({ value, onChange }) => {
  return (
    <div className="w-full max-w-sm px-4 py-2">
      <div className="flex items-center justify-between mb-2">
        <span className="text-sm font-medium text-gray-700">Price Range</span>
        <span className="text-sm text-gray-500">
          ₹{value[0].toLocaleString()} - ₹{value[1].toLocaleString()}
        </span>
      </div>
      <div className="relative py-1">
        <div className="h-1 bg-gray-200 rounded-full">
          <div
            className="absolute h-1 bg-blue-500"
            style={{
              left: `${((value[0] - 1200) / (17000 - 1200)) * 100}%`,
              right: `${100 - ((value[1] - 1200) / (17000 - 1200)) * 100}%`
            }}
          />
        </div>
        <input
          type="range"
          min={1200}
          max={17000}
          value={value[0]}
          onChange={(e) => onChange([parseInt(e.target.value), value[1]])}
          className="absolute w-full h-1 opacity-0 cursor-pointer"
        />
        <input
          type="range"
          min={1200}
          max={17000}
          value={value[1]}
          onChange={(e) => onChange([value[0], parseInt(e.target.value)])}
          className="absolute w-full h-1 opacity-0 cursor-pointer"
        />
      </div>
    </div>
  );
};


// const TopFilter = ({
//   label,
//   options,
//   selected,
//   onChange,
//   variant = 'dropdown' // 'dropdown' or 'button'
// }) => {
//   if (variant === 'button') {
//     return (
//       <button
//         onClick={() => onChange(selected)}
//         className="px-4 py-2.5 flex items-center justify-between gap-2
//                    hover:bg-gray-600 transition-colors
//                    text-gray-700 border-r border-gray-200 font-bold last:border-r-0"
//       >
//         <span className=" text-sm font-bold uppercase">{label}</span>
//         {/* <ChevronDown className="w-4 h-4 text-gray-500" /> */}
//       </button>
//     );
//   }

//   return (
//     <div className="flex-1 relative w-[80%] h-auto bg-gray-700">
//       <div className="relative">
//         {/* <div className="w-[5px] h-32 bg-gray-500"></div> */}
//         <select
//           value={selected}
//           onChange={(e) => {
//             const value = e.target.value;
//             if (value === "") {
//               onChange([]);
//             } else {
//               onChange(prev => {
//                 const isArray = Array.isArray(prev);
//                 if (!isArray) return [value];
//                 return prev.includes(value)
//                   ? prev.filter(item => item !== value)
//                   : [...prev, value];
//               });
//             }
//           }}
//           className="w-full px-4 py-2.5 bg-white appearance-none cursor-pointer
//                      text-black text-sm
//                      border-t border-b border-l font-bold border-gray-100 last:border-r
//                      focus:outline-none focus:ring-0
//                      focus:border-gray-200"
//         >
//           <option value="">All {label}</option>
//           {options.map((option) => {
//             const value = option.id || option;
//             const label = option.label || option;
//             return (
//               <option key={value} value={value}>
//                 {label}
//               </option>
//             );
//           })}
//         </select>

//         <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
//           {/* <ChevronDown className="w-4 h-4 text-gray-400" /> */}
//         </div>
//       </div>

//       {Array.isArray(selected) && selected.length > 0 && (
//         <div className="absolute mt-1 inset-x-0 flex flex-wrap gap-1.5 px-2">
//           {selected.map(item => (
//             <span
//               key={item}
//               className="inline-flex items-center px-2 py-1 rounded-full text-xs
//                          bg-gray-100 text-gray-700"
//             >
//               {item}
//               <button
//                 onClick={() => onChange(selected.filter(i => i !== item))}
//                 className="ml-1.5 hover:text-red-500"
//               >
//                 ×
//               </button>
//             </span>
//           ))}

//         </div>
//       )}

//     </div>

//   );
// };


const ProductSkeleton = () => (
  <div className="bg-white rounded-xl shadow-sm overflow-hidden animate-pulse">
    <div className="aspect-square bg-gray-200" />
    <div className="p-4 space-y-3">
      <div className="h-4 bg-gray-200 rounded w-2/3" />
      <div className="h-6 bg-gray-200 rounded w-3/4" />
      <div className="h-4 bg-gray-200 rounded w-1/4" />
      <div className="h-10 bg-gray-200 rounded w-full mt-4" />
    </div>
  </div>
);


const FilterSort = ({
  sortOption,
  setSortOption,
  onCategoryChange,
  onColorChange,
  onFabricChange,
  onStyleChange,
  onZariChange,
  onPatternChange,
  selectedCategories,
  selectedColors,
  selectedFabric,
  selectedStyle,
  selectedZari,
  selectedPattern
}) => {
  const [showSortModal, setShowSortModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [priceRange, setPriceRange] = useState([1000, 20000]);
  const [activeFilterCategory, setActiveFilterCategory] = useState('PRICE RANGE');
  const sortOptions = ["Default", "Price: Low to High", "Price: High to Low"];

  // Define filters configuration similar to TopFilter
  const filters = [
    {
      id: 'categories',
      label: 'CATEGORY',
      options: INITIAL_CATEGORIES,
      selected: selectedCategories,
      onChange: onCategoryChange,
      type: 'category'
    },
    {
      id: 'colors',
      label: 'COLOUR',
      options: INITIAL_COLORS,
      selected: selectedColors,
      onChange: onColorChange,
      type: 'color'
    },
    {
      id: 'fabric',
      label: 'FABRIC',
      options: ['Cotton', 'Silk', 'Linen', 'Chiffon'],
      selected: selectedFabric,
      onChange: onFabricChange
    },
    {
      id: 'style',
      label: 'STYLE',
      options: ['Traditional', 'Modern', 'Casual', 'Formal'],
      selected: selectedStyle,
      onChange: onStyleChange
    },
    {
      id: 'zari',
      label: 'ZARI',
      options: ['Gold', 'Silver', 'Copper'],
      selected: selectedZari,
      onChange: onZariChange
    },
    {
      id: 'pattern',
      label: 'PATTERN',
      options: ['Floral', 'Geometric', 'Solid', 'Striped'],
      selected: selectedPattern,
      onChange: onPatternChange
    }
  ];

  // Handle filter selection using the same logic as TopFilter
  const handleSelect = (filter, value) => {
    let newSelection;
    if (filter.type === 'category') {
      const category = typeof value === 'object' ? value.id : value;
      newSelection = filter.selected.includes(category)
        ? filter.selected.filter(id => id !== category)
        : [...filter.selected, category];
    } else if (filter.type === 'color') {
      const colorName = value.name;
      newSelection = filter.selected.includes(colorName)
        ? filter.selected.filter(name => name !== colorName)
        : [...filter.selected, colorName];
    } else {
      newSelection = filter.selected.includes(value)
        ? filter.selected.filter(item => item !== value)
        : [...filter.selected, value];
    }

    filter.onChange(newSelection);
  };

  const FilterOption = ({ item, category }) => {
    const filter = filters.find(f => f.label === category);
    if (!filter) return null;

    const isSelected = filter.type === 'color'
      ? filter.selected.includes(item.name)
      : filter.type === 'category'
        ? filter.selected.includes(item.id)
        : filter.selected.includes(item);

    return (
      <button
        onClick={() => handleSelect(filter, item)}
        className="flex items-center justify-between w-full px-6 py-3 hover:bg-gray-50"
      >
        <div className="flex items-center">
          {category === 'COLOUR' && (
            <span
              className="w-6 h-6 rounded-full mr-3 border border-gray-200"
              style={{ backgroundColor: item.value }}
            />
          )}
          <span className="text-gray-900 text-sm">
            {filter.type === 'category' ? item.label
              : filter.type === 'color' ? item.name
                : item}
          </span>
        </div>
        {isSelected && <Check className="w-5 h-5 text-pink-500" />}
      </button>
    );
  };

  const resetFilters = () => {
    filters.forEach(filter => {
      filter.onChange([]);
    });
    setPriceRange([1000, 20000]);
  };

  // Rest of the component remains the same...
  return (
    <div className="fixed inset-x-0 bottom-0 z-50">
      {/* Bottom Navigation */}
      <div className="bg-white border-t border-gray-200">
        <div className="flex">
          <button
            onClick={() => setShowSortModal(true)}
            className="flex-1 flex items-center justify-center space-x-2 py-4 border-r border-gray-200"
          >
            <ArrowDownUp size={18} />
            <span className="text-sm font-medium">SORT</span>
          </button>
          <button
            onClick={() => setShowFilterModal(true)}
            className="flex-1 flex items-center justify-center space-x-2 py-4"
          >
            <Filter size={18} />
            <span className="text-sm font-medium">FILTER</span>
            {filters.some(filter => filter.selected?.length > 0) && (
              <span className="ml-1.5 px-2 py-0.5 text-xs font-semibold bg-black text-white rounded-full">
                {filters.reduce((total, filter) => total + (filter.selected?.length || 0), 0)}
              </span>
            )}
          </button>
        </div>
      </div>

      {/* Sort Modal remains the same... */}
      {showSortModal && (
        <div className="fixed inset-x-0 bottom-0 bg-white z-50 h-1/2 rounded-t-2xl shadow-lg">
          <div className="flex flex-col h-full">
            <div className="flex justify-between items-center px-6 py-4 border-b">
              <h2 className="text-lg font-semibold">SORT BY</h2>
              <button onClick={() => setShowSortModal(false)}>
                <X size={24} />
              </button>
            </div>
            <div className="flex-1 overflow-y-auto">
              {sortOptions.map((option) => (
                <button
                  key={option}
                  onClick={() => {
                    setSortOption(option);
                    setShowSortModal(false);
                  }}
                  className="flex justify-between items-center w-full px-6 py-4 hover:bg-gray-50"
                >
                  <span className="text-sm text-gray-900">{option}</span>
                  {sortOption === option && <Check className="w-5 h-5 text-pink-500" />}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Filter Modal - Updated to use new filter logic */}
      {showFilterModal && (
        <div className="fixed inset-x-0 bottom-0 bg-white z-50 h-1/2 rounded-t-2xl shadow-lg">
          <div className="flex h-full">
            <div className="w-32 border-r bg-gray-50 flex flex-col">
              <div className="sticky top-0 bg-white border-b z-10">
                <h2 className="px-4 py-4 font-semibold text-gray-900">FILTERS</h2>
              </div>
              <div className="flex-1 overflow-y-auto">
                {filters.map((filter) => (
                  <button
                    key={filter.id}
                    onClick={() => setActiveFilterCategory(filter.label)}
                    className={`w-full text-left px-4 py-3 text-sm border-b
                      ${activeFilterCategory === filter.label
                        ? 'text-pink-500 bg-white font-medium'
                        : 'text-gray-700'}`}
                  >
                    {filter.label}
                    {filter.selected?.length > 0 && (
                      <span className="ml-1.5 text-xs font-semibold text-pink-500">
                        ({filter.selected.length})
                      </span>
                    )}
                  </button>
                ))}
              </div>
            </div>

            <div className="flex-1 flex flex-col max-h-1/2">
              <div className="flex justify-between items-center px-6 py-4 border-b bg-white">
                <button onClick={() => setShowFilterModal(false)}>
                  <X size={24} />
                </button>
                <button
                  onClick={resetFilters}
                  className="text-pink-500 text-sm font-medium"
                >
                  Reset All
                </button>
              </div>

              <div className="flex-1 overflow-y-auto">
                {activeFilterCategory === 'PRICE RANGE' ? (
                  <div className="p-6">
                    <PriceRangeSlider priceRange={priceRange} setPriceRange={setPriceRange} />
                  </div>
                ) : (
                  <div className="py-2">
                    {filters.find(f => f.label === activeFilterCategory)?.options.map((item, idx) => (
                      <FilterOption
                        key={idx}
                        item={item}
                        category={activeFilterCategory}
                      />
                    ))}
                  </div>
                )}
              </div>

              <div className="border-t bg-white p-4">
                <div className="flex space-x-3">
                  <button
                    onClick={() => setShowFilterModal(false)}
                    className="flex-1 py-3 px-6 border rounded-lg text-sm font-medium"
                  >
                    CLOSE
                  </button>
                  <button
                    onClick={() => setShowFilterModal(false)}
                    className="flex-1 py-3 px-6 rounded-lg bg-pink-500 text-white text-sm font-medium"
                  >
                    APPLY FILTERS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};