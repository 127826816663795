import React, { useState } from 'react';

const PriceRangeSlider = ({ priceRange, setPriceRange }) => {
  // Constants
  const min = 1000;
  const max = 100000;
  const step = 1000; // Smaller step for finer control
  const range = max - min;

  // Calculate percentages for slider positions
  const leftPercentage = ((priceRange[0] - min) / range) * 100;
  const rightPercentage = ((priceRange[1] - min) / range) * 100;

  // Handle slider changes
  const handleSliderChange = (e, index) => {
    const value = parseInt(e.target.value, 10);
    const newRange = [...priceRange];

    // Round to nearest step for smoother feeling
    const roundedValue = Math.round(value / step) * step;

    // Ensure values don't cross and maintain minimum gap
    if (index === 0) {
      const maxAllowed = priceRange[1] - 1000;
      newRange[0] = Math.min(roundedValue, maxAllowed);
    } else {
      const minAllowed = priceRange[0] + 1000;
      newRange[1] = Math.max(roundedValue, minAllowed);
    }

    setPriceRange(newRange);
  };

  // To handle click events that trigger a smooth transition without dragging
  const handleClick = (e, index) => {
    const value = parseInt(e.target.value, 10);
    const newRange = [...priceRange];
    
    // Round to nearest step for smoother feeling
    const roundedValue = Math.round(value / step) * step;
    
    if (index === 0) {
      const maxAllowed = priceRange[1] - 1000;
      newRange[0] = Math.min(roundedValue, maxAllowed);
    } else {
      const minAllowed = priceRange[0] + 1000;
      newRange[1] = Math.max(roundedValue, minAllowed);
    }
    
    setPriceRange(newRange);
  };

  return (
    <div className="w-full px-4 py-4">
      {/* Slider Container */}
      <div className="relative h-2 mt-8 mb-4">
        {/* Track Background */}
        <div className="absolute inset-0 w-full bg-gray-200 rounded-full" />

        {/* Active Track */}
        <div
          className="absolute inset-0 bg-black rounded-full"
          style={{
            left: `${leftPercentage}%`,
            right: `${100 - rightPercentage}%`,
            transition: 'all 0.1s ease-in-out', // Add smooth transition for active track
          }}
        />

        {/* Min Price Input */}
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={priceRange[0]}
          onChange={(e) => handleSliderChange(e, 0)}
          onClick={(e) => handleClick(e, 0)} // Add click event for smooth transition
          className="absolute w-full top-0 h-full appearance-none bg-transparent cursor-pointer"
          style={{
            WebkitAppearance: 'none',
            zIndex: 2,
            transition: 'all 0.1s ease-in-out', // Smooth transition for slider input
          }}
        />

        {/* Max Price Input */}
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={priceRange[1]}
          onChange={(e) => handleSliderChange(e, 1)}
          onClick={(e) => handleClick(e, 1)} // Add click event for smooth transition
          className="absolute w-full top-0 h-full appearance-none bg-transparent cursor-pointer"
          style={{
            WebkitAppearance: 'none',
            zIndex: 1,
            transition: 'all 0.1s ease-in-out', // Smooth transition for slider input
          }}
        />

        {/* Custom Thumb Styles */}
        <style jsx>{`
          input[type='range'] {
            pointer-events: none;
            transition: all 0.2s ease-in-out; /* Smooth transition for thumb */
          }

          input[type='range']::-webkit-slider-thumb {
            -webkit-appearance: none;
            pointer-events: auto;
            width: 28px; /* Made thumb larger */
            height: 28px;
            background: white;
            border: 2px solid black;
            border-radius: 50%;
            cursor: grab;
            margin-top: -13px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: all 0.2s ease-in-out; /* Smooth transition */
          }

          input[type='range']::-webkit-slider-thumb:hover {
            transform: scale(1.1);
          }

          input[type='range']::-webkit-slider-thumb:active {
            cursor: grabbing;
            transform: scale(1.15);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }

          input[type='range']::-moz-range-thumb {
            pointer-events: auto;
            width: 28px;
            height: 28px;
            background: white;
            border: 2px solid black;
            border-radius: 50%;
            cursor: grab;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: all 0.2s ease-in-out; /* Smooth transition */
          }

          input[type='range']::-moz-range-thumb:hover {
            transform: scale(1.1);
          }

          input[type='range']::-moz-range-thumb:active {
            cursor: grabbing;
            transform: scale(1.15);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }

          input[type='range']::-moz-range-track {
            background: transparent;
          }

          input[type='range']::-webkit-slider-runnable-track {
            background: transparent;
            cursor: pointer;
          }
        `}</style>
      </div>

      {/* Price Display */}
      <div className="flex justify-between mt-6">
        <div className="text-sm font-medium">
          ₹{priceRange[0].toLocaleString('en-IN')}
        </div>
        <div className="text-sm font-medium">
          ₹{priceRange[1].toLocaleString('en-IN')}
        </div>
      </div>
    </div>
  );
};

export default PriceRangeSlider;
