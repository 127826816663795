import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';

const SimpleIcon = ({ paths }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    className="w-12 h-12" 
    fill="none" 
    stroke="currentColor"
    strokeWidth="1"
  >
    {paths}
  </svg>
);

const promises = [
  {
    icon: (
      <SimpleIcon paths={
        <path d="M12 3l8 4v10l-8 4-8-4V7l8-4zm0 18V3m0 18l-8-4m8 4l8-4M4 7l8 4m0 0l8-4" />
      } />
    ),
    title: 'Exclusive Design',
    description: 'Own exclusive sarees that tell a unique story—each one crafted just for you'
  },
  {
    icon: (
      <SimpleIcon paths={
        <path d="M4 4h16v16H4zm4 4h8m-8 4h8" />
      } />
    ),
    title: 'Directly from Manufacturers',
    description: 'We connect you directly with skilled artisans, eliminating middlemen'
  },
  {
    icon: (
      <SimpleIcon paths={
        <path d="M12 8l4 4-4 4m-4-4h8" />
      } />
    ),
    title: 'Best Price Challenge',
    description: 'We promise unbeatable prices without compromising on quality'
  },
  {
    icon: (
      <SimpleIcon paths={
        <path d="M12 21a9 9 0 100-18 9 9 0 000 18zm0-7l3-3m-3 3l-3-3m3 7V5" />
      } />
    ),
    title: 'Handcrafted with Love',
    description: 'Each piece is thoughtfully handcrafted with love and dedication'
  },
  {
    icon: (
      <SimpleIcon paths={
        <path d="M5 12h14m-7-7v14m5-8l-5 8m5-8l-5-4" />
      } />
    ),
    title: 'Fast Delivery',
    description: 'Swift and reliable delivery ensures your saree arrives safely'
  },
  {
    icon: (
      <SimpleIcon paths={
        <path d="M12 3a9 9 0 019 9v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4a9 9 0 019-9zm0 4a2 2 0 100 4 2 2 0 000-4z" />
      } />
    ),
    title: 'Secure Transaction',
    description: 'Shop with confidence, your transactions are secure and protected'
  }
];

const OurPromises = () => {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-10">
          <h2 className="text-2xl font-normal text-gray-900">Kalaksha Promises</h2>
          <p className="mt-2 text-sm font-light text-gray-500">The promises that we will never break</p>
        </div>

        <Swiper
          modules={[Autoplay]}
          spaceBetween={20}
          slidesPerView={2}
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 4 }
          }}
          className="pb-6"
        >
          {promises.map((promise, index) => (
            <SwiperSlide key={index}>
              <div className="flex flex-col items-center text-center p-4 border border-[#C5A572] pb-8 rounded-lg bg-white h-44">
                <div className="text-[#C5A572] mb-3">
                  {promise.icon}
                </div>
                <h3 className="text-sm font-medium text-gray-900 mb-2">
                  {promise.title}
                </h3>
                <p className="text-[11px] text-gray-500  font-light">
                  {promise.description}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default OurPromises;