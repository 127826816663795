import React from 'react';
import { useNavigate } from 'react-router-dom';
import Price1 from '../../../asstes/PriceBy/under4k.jpg'
import Price2 from '../../../asstes/PriceBy/4t10k.jpg'
import Price3 from '../../../asstes/PriceBy/10t20k.jpg'
import Price4 from '../../../asstes/PriceBy/above20k.jpg'


const Shopbyprice = () => {
  const navigate = useNavigate();
  
  const priceRanges = [
    { 
      price: 'Under ₹4k', 
      image: Price1,
      href: '/shop?minPrice=0&maxPrice=4000'
    },
    { 
      price: '₹4k - ₹8k', 
      image: Price2,
      href: '/shop?minPrice=4000&maxPrice=8000'
    },
    { 
      price: '₹8k - ₹15k', 
      image: Price3,
      href: '/shop?minPrice=8000&maxPrice=15000'
    },
    { 
      price: '₹15k - ₹30k', 
      image: Price4,
      href: '/shop?minPrice=15000&maxPrice=30000'
    },

  ];

  const handleNavigate = (href) => {
    navigate(href);
  };

  return (
    <div className="w-full bg-white py-8">
      <div className="max-w-6xl mx-auto px-4">
      <h2 className="text-3xl font-serif text-center mb-2">Shop by Price</h2>
      <p className="text-center text-xs px-2 lg:px-20  md:text-base text-gray-600 mb-8">
      Elegance Within Reach: Explore Timeless Sarees that complement both your style and
      budget, without sacrificing the artistry and quality you deserve.e
        </p>
        
        <div className="grid grid-cols-2  md:grid-cols-4 gap-3">
          {priceRanges.map((item, index) => (
            <div 
              key={index} 
              className="flex flex-col items-center cursor-pointer hover:opacity-90 transition-opacity"
              onClick={() => handleNavigate(item.href)}
              role="link"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleNavigate(item.href);
              }}
            >
              <div className="w-full aspect-square overflow-hidden rounded-sm">
                <img
                  src={item.image}
                  alt={`Price range ${item.price}`}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="mt-2 text-xs sm:text-sm text-gray-900 text-center">
                {item.price}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Shopbyprice;