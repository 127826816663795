import React, { useState, useEffect } from 'react';
import { Heart, ShoppingBag, X } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { addItemToCart } from '../../Redux/Customers/Cart/Action';
import { addToWishlist, S3_BASE_URL } from '../../config/api';

const ProductModal = ({ product, isOpen, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen && product) {
      setIsLoading(true);
      setError(null);
      const timer = setInterval(() => {
        setCurrentImageIndex(prev => 
          prev === product.imageUrls.length - 1 ? 0 : prev + 1
        );
      }, 3000);
      setIsLoading(false);
      return () => clearInterval(timer);
    }
  }, [isOpen, product]);

  // Reset states when modal closes
  useEffect(() => {
    if (!isOpen) {
      setCurrentImageIndex(0);
      setError(null);
    }
  }, [isOpen]);

  if (!isOpen || !product) return null;
  if (error) return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="bg-white p-6 rounded-lg">
        <p className="text-red-500">{error}</p>
        <button 
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-gray-200 rounded-lg"
        >
          Close
        </button>
      </div>
    </div>
  );

  const handleAddToCart = async (id) => {
    try {
      const jwt = localStorage.getItem("jwt");
      if (!jwt) {
        setError("Please login to add items to cart");
        return;
      }
  
      const reqData = {
        jwt,
        data: {
          productId: id,
          quantity: 1
        }
      };
  
      await dispatch(addItemToCart(reqData));
      onClose();
    } catch (err) {
      setError("Failed to add item to cart");
      console.error("Cart error:", err);
    }
  };

  const handleAddToWishlist = async (id) => {
    try {
      await addToWishlist(id);
    } catch (err) {
      setError("Failed to add item to wishlist");
      console.error("Wishlist error:", err);
    }
  };

  const getImageUrl = (imageUrl) => {
    if (!imageUrl) return '/placeholder-image.jpg';
    try {
      const cleanUrl = imageUrl.startsWith('/') ? imageUrl.slice(1) : imageUrl;
      return `${process.env.REACT_APP_S3_BASE_URL || ''}${cleanUrl}`;
    } catch (err) {
      console.error("Image URL error:", err);
      return '/placeholder-image.jpg';
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 p-4">
      <div className="relative w-full max-w-4xl overflow-hidden rounded-3xl bg-white">
        <div className="flex flex-col md:flex-row">
          {/* Image Section */}
          <div className="relative aspect-square w-full md:w-3/5 overflow-hidden">
            {isLoading ? (
              <div className="h-full w-full bg-gray-200 animate-pulse" />
            ) : (
              <>
                {/* Navigation dots */}
                <div className="absolute right-4 top-1/2 z-10 flex -translate-y-1/2 flex-col gap-2">
                  {product.imageUrls.map((_, idx) => (
                    <button
                      key={idx}
                      onClick={() => setCurrentImageIndex(idx)}
                      className={`h-2 w-2 rounded-full ${
                        currentImageIndex === idx ? 'bg-white' : 'bg-white/50'
                      } transition-all duration-300`}
                    />
                  ))}
                </div>
                
                {/* Close button */}
                <button 
                  onClick={onClose}
                  className="absolute right-4 top-4 z-20 flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-md hover:bg-gray-50 transition-colors"
                >
                  <X className="h-5 w-5 text-gray-500" />
                </button>

                {/* Image slider */}
                <div 
                  className="flex h-full transition-transform duration-700 ease-in-out"
                  style={{ transform: `translateX(-${currentImageIndex * 100}%)` }}
                >
                  {product.imageUrls.map((image, idx) => (
                    <div key={idx} className="relative w-full h-full flex-shrink-0">
                      <img
                        src={ S3_BASE_URL+ getImageUrl(image)}
                        alt={`${product.title || 'Product'} view ${idx + 1}`}
                        className="h-full w-full flex-shrink-0 object-cover"
                        onError={(e) => {
                          console.error('Image failed to load:', getImageUrl(image));
                          e.target.src = '/placeholder-image.jpg';
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>

          {/* Info Section */}
          <div className="flex flex-col p-6 md:w-2/5">
            <div className="flex-1">
              {product.saleTag && (
                <span className="inline-block bg-emerald-600 text-white text-xs px-2 py-1 rounded">
                  {product.saleTag}
                </span>
              )}
              <h2 className="mt-3 text-xl font-medium text-gray-900">
                {product.title || 'Untitled Product'}
              </h2>
              
              <div className="mt-4">
                <p className="text-sm text-gray-600">{product.description}</p>
              </div>

              <div className="mt-6">
                <div className="flex items-center gap-2">
                  <span className="text-2xl font-semibold">
                    ₹{(product.price || 0).toLocaleString()}
                  </span>
                  {product.mrp && product.mrp > product.price && (
                    <span className="text-sm text-gray-500 line-through">
                      ₹{product.mrp.toLocaleString()}
                    </span>
                  )}
                </div>
                {product.mrp && product.mrp > product.price && (
                  <span className="mt-1 inline-block text-sm text-green-600">
                    {Math.round(((product.mrp - product.price) / product.mrp) * 100)}% OFF
                  </span>
                )}
                <p className="mt-2 text-xs text-gray-500">Inclusive of all taxes</p>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="mt-6 space-y-3">
              <button 
                onClick={() => handleAddToCart(product.id)}
                disabled={isLoading}
                className="w-full rounded-full bg-gradient-to-br from-emerald-900 via-emerald-600 to-emerald-400 py-3.5 text-sm font-medium text-white transition-colors hover:opacity-90 disabled:opacity-50"
              >
                {isLoading ? 'Adding...' : 'ADD TO BAG'}
              </button>
              <a href={`/product/${product.id}`}>
                <button className="w-full mt-2 rounded-full border border-gray-200 py-3.5 text-sm font-medium text-gray-800 transition-colors hover:bg-gray-50">
                  VIEW DETAILS →
                </button>
              </a>
            </div>

            {/* Footer Actions */}
            <div className="mt-6 flex justify-start gap-6 border-t border-gray-100 pt-4">
              <button 
                onClick={() => handleAddToWishlist(product.id)}
                disabled={isLoading}
                className="flex items-center gap-2 transition-colors hover:text-pink-500 disabled:opacity-50"
              >
                <Heart className="h-5 w-5" />
                <span className="text-xs">Wishlist</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;